import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { adminPrasadViewLabel } from "../../utils/ConstantsMessages";

interface ManagePrasadWeightageDilogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (weightage: string) => void;
  selectedTemple:any,
}

const ManagePrasadWeightageDilog: React.FC<ManagePrasadWeightageDilogProps> = ({
  open,
  onClose,
  onSubmit,
  selectedTemple,
}) => {
  const [weightage, setWeightage] = React.useState("");
  const [formError,setFormError]= useState<boolean>(false);
  useEffect(()=>{
    if(selectedTemple){
      setWeightage(selectedTemple.chadhavaWeightageForTemple)
    }
  },[selectedTemple])
  
  const handleSubmit = () => {
    if(!weightage){
      setFormError(true)
      return;
    }
    onSubmit(weightage)
    setFormError(false)
    handleCloseWeightageDialog()
  };

  const handleWeightageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFormError(false)
    setWeightage(newValue);
  };

  const handleCloseWeightageDialog = ()=>{
    onClose()
    setFormError(false)
    setWeightage(selectedTemple.chadhavaWeightageForTemple)
  }

  return (
    <Dialog open={open} onClose={handleCloseWeightageDialog}>
      <DialogTitle>{adminPrasadViewLabel?.PrasadWeightageDialogTitle}</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <Box>
          <TextField
            label={adminPrasadViewLabel?.WeightageLabel}
            type="number"
            fullWidth
            value={weightage || ""}
            onChange={handleWeightageChange}
            margin="normal"
            error={formError}
            helperText={ formError ? adminPrasadViewLabel?.WeightageErrorMessage  : "" }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWeightageDialog}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagePrasadWeightageDilog;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { Stack } from "react-bootstrap";
import MultipleSelectAutocomplete from "../../../components/AutoComplete/MultipleSelectAutocomplete";
import AddWeightageDialog from "./AddWeightageDialog";
import "./SpecialPujaForm.scss";

interface SpecialPujaFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (formData: any) => void;
  templeList: any;
  handleTempleSelect: ([]: any) => any;
  allPujaList: any;
  handlePujaSelect: (pujaList: any) => any;
  seletedTempleOptions: any;
  seletedPujaOptions: any;
  selectedSpecialPuja: any;
  setSeletedTempleOptions: ([]: any) => any;
  setSeletedPujaOptions: ([]: any) => any;
}

const SpecialPujaForm: React.FC<SpecialPujaFormModalProps> = ({
  isOpen,
  onClose,
  onSave,
  templeList,
  handleTempleSelect,
  allPujaList,
  handlePujaSelect,
  seletedTempleOptions,
  seletedPujaOptions,
  selectedSpecialPuja,
  setSeletedTempleOptions,
  setSeletedPujaOptions,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    pujaName: "",
    pujaNameKey: "",
    specialPujaWeightage: "",
    isPromotion: false,
    dashBoardImage: "",
    pujaListImage: "",
  });
  const [errors, setErrors] = useState<any>({
    pujaName: "",
    specialPujaWeightage: "",
    dashBoardImage: "",
    pujaListImage: "",
    templeList: "",
    pujaList: "",
  });
  const [localImageDashBoard, setLocalImageDashBoard] = useState<any>("");
  const [localImagePujaList, setLocalImagePujaList] = useState<any>("");
  const [isAddWeightageDialogOpen, setIsAddWeightageDialogOpen] =
    useState(false);
  const [selectedTemple, setSelectedTemple] = useState<any>(null);
  const [weightageContext, setWeightageContext] = useState<"Temple" | "Puja">(
    "Temple"
  ); 
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false)
  useEffect(() => {
    if (selectedSpecialPuja) {
      let loadFormData: any = {
        id: selectedSpecialPuja?.id,
        pujaName: selectedSpecialPuja?.pujaName,
        pujaNameKey: selectedSpecialPuja?.pujaNameKey,
        specialPujaWeightage: selectedSpecialPuja?.specialPujaWeightage,
        isPromotion: selectedSpecialPuja?.isPromotion,
        dashBoardImage: selectedSpecialPuja?.dashBoardBg?.uri,
        pujaListImage: selectedSpecialPuja?.pujaListBg?.uri,
      };
      setFormData({ ...loadFormData });
    }
  }, [selectedSpecialPuja]);

  const resetForm = () => {
    setLocalImageDashBoard("");
    setLocalImagePujaList("");
    setSeletedTempleOptions([]);
    setSeletedPujaOptions([])
    setFormData({
      id: "",
      pujaName: "",
      pujaNameKey: "",
      specialPujaWeightage: "",
      isPromotion: false,
      dashBoardImage: "",
      pujaListImage: "",
    });
    setErrors({
      pujaName: "",
      specialPujaWeightage: "",
      dashBoardImage: "",
      pujaListImage: "",
      templeList: "",
      pujaList: "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    setErrors((prev:any) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "name") {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(value)) return;
    }

    if (name === "specialPujaWeightage") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) return;
    }
    if (type === "file" && files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (name === "dashBoardImage") {
          setLocalImageDashBoard(e.target?.result);
        }
        if (name === "pujaListImage") {
          setLocalImagePujaList(e.target?.result);
        }
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  const validateForm = () => {
    const newErrors = {
      pujaName: formData?.pujaName?.trim() ? "" : "Name is required",
      specialPujaWeightage:
        formData?.specialPujaWeightage?.trim() &&
        Number(formData?.specialPujaWeightage) > 0
          ? ""
          : "Weightage must be a positive number",
      dashBoardImage:
        localImageDashBoard || formData?.dashBoardImage
          ? ""
          : "Dashboard Image is required",
      pujaListImage:
        localImagePujaList || formData?.pujaListImage
          ? ""
          : "PujaList Image is required",
      templeList : seletedTempleOptions?.length > 0 ? "" : "Plese select temples",
      pujaList:  seletedPujaOptions?.length > 0 ? "" : "Plese select pujas"
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsFormSubmit(true)
    await onSave(formData);
    resetForm();
    setIsFormSubmit(false)
  };

  const handleCloseFormModal = () => {
    onClose();
    resetForm();
  };

  const handleOpenWeightageDialog = (context: "Temple" | "Puja", temple?: any) => {
    setWeightageContext(context);
    setSelectedTemple(temple || null);
    setIsAddWeightageDialogOpen(true);
  };

  const handleCloseWeightageDialog = () => {
    setIsAddWeightageDialogOpen(false);
    setSelectedTemple(null);
  };
  const handleWeightageSubmit = (weightage: string) => {
    
    handleCloseWeightageDialog();
  };


  return (
  <>
    <Dialog open={isOpen} onClose={handleCloseFormModal} className="specialPujaAdminDialog">
      <DialogTitle className="spAdminDialogTitle">{selectedSpecialPuja ? "Edit" : "Add"} Special Puja Item</DialogTitle>
      <DialogContent className="innerContent" sx={{ minWidth: 600 }}>
        <TextField
        className="itemList"
          name="pujaName"
          label="Special Puja Name"
          fullWidth
          margin="normal"
          value={formData?.pujaName}
          onChange={handleChange}
          error={!!errors?.pujaName}
          helperText={errors?.pujaName}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <TextField
        className="itemList"
          name="specialPujaWeightage"
          label="Special Puja Weightage"
          fullWidth
          margin="normal"
          type="number"
          InputProps={{
            inputMode: "numeric",
          }}
          value={formData?.specialPujaWeightage}
          onChange={handleChange}
          error={!!errors?.specialPujaWeightage}
          helperText={errors?.specialPujaWeightage}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <Box className="itemList">
          <MultipleSelectAutocomplete
            options={templeList}
            label="Temple List"
            placeholder="Choose Temple"
            onChange={(evt:any)=>{
              setErrors((prev:any)=>{
                return {
                  ...prev,
                  templeList: "",
                }
              })
              handleTempleSelect(evt)
            }}
            alreadySelectedOptions={seletedTempleOptions}
          />
          {!!errors?.templeList && (<Box sx={{color: "#d32f2f", margin: "0",fontFamily: "Roboto"}} > {errors?.templeList} </Box>)}
        </Box>

        <Box className="itemList">
          <MultipleSelectAutocomplete
            options={allPujaList}
            label="Puja List"
            placeholder="Choose Puja"
            onChange={(evt:any)=>{
              setErrors((prev:any)=>{
                return {
                  ...prev,
                  pujaList: "",
                }
              })
              handlePujaSelect(evt)
            }}
            alreadySelectedOptions={seletedPujaOptions}
          />
          {!!errors?.pujaList && (<Box sx={{color: "#d32f2f", margin: "0",fontFamily: "Roboto"}} > {errors?.pujaList} </Box>)}
        </Box>

        <Box className="weightageBtnRow itemList">
            <Button
              variant="outlined"
              onClick={() => handleOpenWeightageDialog("Temple", selectedTemple)}
            >
              Add Temple Weightage
            </Button>
 
            <Button
              variant="outlined"
              onClick={() => handleOpenWeightageDialog("Puja", selectedTemple)}
            >
              Add Puja Weightage
            </Button>
          </Box>

        <Stack className="chooseImgInfo itemList">
          <TextField
            name="dashBoardImage"
            label="Deshboard Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            error={!!errors?.dashBoardImage}
            helperText={errors?.dashBoardImage}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          {localImageDashBoard?.length == 0 &&
            formData?.dashBoardImage?.length > 0 && (
              <img src={formData?.dashBoardImage} />
            )}
          {localImageDashBoard?.length > 0 && <img src={localImageDashBoard} />}
        </Stack>

        <Stack className="chooseImgInfo itemList">
          <TextField
            name="pujaListImage"
            label="Puja List Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            error={!!errors?.pujaListImage}
            helperText={errors?.pujaListImage}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          {localImagePujaList?.length == 0 &&
            formData?.pujaListImage?.length > 0 && (
              <img src={formData?.pujaListImage} />
            )}
          {localImagePujaList?.length > 0 && <img src={localImagePujaList} />}
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.isPromotion}
              onChange={handleChange}
              name="isPromotion"
            />
          }
          label="Check To Enable"
        />
      </DialogContent>
      <DialogActions className="spAdminModalFooter">
        <Button onClick={handleCloseFormModal} className="btnCancel grey">
          Cancel
        </Button>
        <Button className="saveBtn" onClick={handleSubmit} variant="outlined" color="primary" disabled={isFormSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>

    <AddWeightageDialog
    open={isAddWeightageDialogOpen}
    onClose={handleCloseWeightageDialog}
    onSubmit={handleWeightageSubmit}
    seletedTempleOptions={seletedTempleOptions}
    context={weightageContext}
    seletedPujaOptions={seletedPujaOptions}
    setSeletedPujaOptions={setSeletedPujaOptions}
    setSeletedTempleOptions={setSeletedTempleOptions}
    templeList = {templeList}
    allPujaList={allPujaList}
 />
 </>
  );
};

export default SpecialPujaForm;

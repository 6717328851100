import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SubtitleTypography from "../../../components/typography/SubtitleTypography";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ButtonGroup } from "react-bootstrap";
import MorPankh from "../../../assets/morpankh.png";
import TempleFlag from "../../../assets/temple-flag.png";
import LadduImg from "../../../assets/laddu.png";
import RemoveIcon from "@mui/icons-material/Remove";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import ParagraphTypography from "../../../components/typography/ParagraphTypography";
import TitleTypography from "../../../components/typography/TitleTypography";
import PhonePe from "../../../assets/phonepe.png";
import PayTm from "../../../assets/paytm.png";
import BankImg from "../../../assets/bank.png";
import TempleImg from "../../../assets/temple-icon.png";
import LocationImg from "../../../assets/loc-icon.png";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import EastIcon from "@mui/icons-material/East";
import EditIcon from "@mui/icons-material/Edit";
import pujaImg from "../../../assets/promo-puja-banner.png";
import couponApplyIcon from "../../../assets/coupon-tick.png";
import couponPercentIcon from "../../../assets/coupon-percent.png";

import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
  CHADHAVA_ADDON_SCROLL_RESPONSIVE,
} from "../../../utils/Constants";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Dialog from "@mui/material/Dialog";
import ChooseAddress from "../../../components/chooseaddress/ChooseAddress";
import "./CartView.scss";
import CouponCodeDialog from "./CouponCodeDialog";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { dakshinaPandit, orderType } from "../../../utils/Constants";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
// import { getPriceConversionRate } from "../../../store/actions/priceConversionAction";
import prasadImg from "../../../assets/prasad-img.jpg";
import AlertDialog from "../../../components/dialog/AlertDialog";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { disclaimerPrasadText, POP_UP_MESSAGE } from "../../../utils/ConstantsMessages";
import { calculateOverSeasPrice } from "../../../utils/HelperFunctions";

function ReviewCartView(props: any) {
  const priceData = props.priceBreakup;
  const userDetails = props.userDetails;
  const {
    isOverseas = false,
    getSymbolWithPrice,
    getChadhavaFinalPaymentValue,
  } = props;
  const {
    setOutSideIndiaMessagePopup,
    outSideIndiaMessagePopup,
    isPrasad,
    selectedAddressDetails,
    setIsPrasad,
    handlePrasadCheckboxChange,
    setChooseAddressBox,
    isCountryDisabledForBooking,
  } = props;


  // const handleSetCouponApplyResponse = () => {
  //   props.setCouponApplyResponse({
  //     ...props.couponApplyResponse,
  //     isApplied: true,
  //   });
  //   props.setCouponPopUp(false);
  // };
  const [selectedDakshina, setSelectedDakshina] = useState<number | string>(0);
  const [isEditing, setIsEditing] = useState(false);
  const [customDakshina, setCustomDakshina] = useState<any>(0);
  const [
    openAlertDialogForBookingNotAllowed,
    setOpenAlertDialogForBookingNotAllowed,
  ] = useState<any>(false);
  const [openPriceErrorDialog, setOpenPriceErrorDialog] =
    useState<boolean>(false);
  const cartDetails = props?.cartDetails ? props?.cartDetails : null;
  const templeDetails = props?.templeDetails ? props?.templeDetails : null;
  const memberDetails =
    props?.memberDetails && props?.memberDetails?.length > 0
      ? props?.memberDetails[0]
      : null;

  const chadhavaEnablity =  cartDetails?.orderType === orderType.chadhava ? true : false;
  const prasadEnablity =  cartDetails?.orderType === orderType.prasad ? true : false;
  const pujaEnablity =  cartDetails?.orderType === orderType.puja ? true : false;

  const  cartPageTitle = cartDetails?.orderType === orderType.chadhava ? "Chadhava" : "Prasad";
 
  const selectedChadhvaList = cartDetails?.selectedChadhvaList
    ? cartDetails?.selectedChadhvaList?.filter((item: any) => item?.count > 0)
    : [];
  if (selectedChadhvaList.length === 0 && chadhavaEnablity) {
    props.handRedirectToChadhavaPage();
  }
  
  if (selectedChadhvaList.length === 0 && prasadEnablity) {
    props.handRedirectToPrasadPage();
  }

  const chadhavaList = cartDetails?.selectedChadhvaList
    ? cartDetails?.selectedChadhvaList?.filter(
        (item: any) => item?.count === 0 && item.disabled === false
      )
    : [];

  const increment = (item: any) => {
    props.handleIncrement(item);
  };

  const decrement = (item: any) => {
    props.handleDecrement(item);
  };
  const addChadhava = (item: any) => {
    //props.handleAddChadhava(item);
    props.handleIncrement(item);
  };

  const handleLabelClick = () => {
    setIsEditing(true);
    if (customDakshina === "custom") {
      setCustomDakshina("");
    }
    setCustomDakshina("");
    props?.setPanditDakshina(0);
    setSelectedDakshina(0);
  };

  const isValidNumber = (value: any): boolean => {
    if (value.trim() === "") return true;

    const isNumber = !isNaN(value) && value.trim() !== "";
    const hasFourDigits = /^[0-9]{1,4}$/.test(value?.trim());
    return isNumber && hasFourDigits;
  };

  const handleSetCutstomDakshinaChange = (event: any) => {
    if (!isValidNumber(event.target.value)) return false;
    setSelectedDakshina(event.target.value);
    setCustomDakshina(event.target.value);
    props?.setPanditCustomeDakshina(event.target.value);
  };

  const handleSetCutstomDakshinaBlur = (e: any) => {
    if (!isValidNumber(e.target.value)) return false;
    setSelectedDakshina(e.target.value);
    setIsEditing(false);
    setCustomDakshina(e.target.value);
    props?.setPanditCustomeDakshina(e.target.value);
  };

  const handleSetCutstomDakshinaKeyDown = (event: any) => {
    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }

    if (!isValidNumber(event.target.value)) return false;

    setSelectedDakshina(event.target.value);
    setCustomDakshina(event.target.value);
    props?.setPanditCustomeDakshina(event.target.value);
    if (event.key === "Enter") {
      setIsEditing(false); // Hide the TextField when Enter is pressed
    }
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    props.clearCart();
  };

  const handleCloseAlertDialogForBookingNotAllowed = () => {
    setOpenAlertDialogForBookingNotAllowed(false);
  };

  const handleCloseAlertDialogForPrice = () => {
    setOpenPriceErrorDialog(false);
  };

  
  return (
    <Box className="secondStep AddDevoteeDetails">
      { (chadhavaEnablity || prasadEnablity ) ? (
        <Box className="chadhavaDetails">
          <SubtitleTypography title={`Review ${cartPageTitle} Details`}></SubtitleTypography>
          {/* Add Devotee */}
          <Box className="addDevotee">
            <Box className="devoteeDetails">
              <SubtitleTypography title="Devotee Details"></SubtitleTypography>
              <Button
                className="editDevotee"
                onClick={() => {
                  props.editPujaOptions();
                }}
              >
                Edit Devotee
              </Button>
            </Box>

            <Box className="formGroup">
              <Box className="formField lightOpa">
                <label>Temple Name</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={`${templeDetails?.personalInfo?.name}`}
                  variant="standard"
                />
              </Box>
              {/* {memberDetails.map((item: any, id: any) => {
                return ( */}
              <Box className="formField lightOpa">
                <label>Full Name of Devotee *</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={memberDetails?.fullName}
                  variant="standard"
                />
              </Box>
              {/* );
              })} */}
              { (!prasadEnablity ) ? (
              <Box className="formField lightOpa">
                <label>Gotram (Kashyapa Gotram, if not known)</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={
                    memberDetails?.gotram ? memberDetails?.gotram : "Kashyapa"
                  }
                  variant="standard"
                />
              </Box>
              ):""}
              <Box className="formField lightOpa">
                <label>Provide Your WhatsApp Number *</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={`${cartDetails?.dialCode} ${cartDetails?.whatsAppNumber}`}
                  variant="standard"
                />
              </Box>
            </Box>
          </Box>


           {/* Address */}
           {prasadEnablity ? userDetails ? (
            <>
              <Box className="cardDesign addressSce">
                <Typography className="addressTitle">
                  {/* {" "}
                  {props.selectedAddressDetails
                    ? props.selectedAddressDetails.savedAs ||
                    props.selectedAddressDetails.name
                    : "Address"} */}
                 Delivery Address 
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                  spacing={1}
                  onClick={() => {
                    if (props.selectedAddressDetails) {
                      props.setChooseAddressBox(true);
                    } else {
                      props.navigateToAddressScreen();
                    }
                  }}
                >
                  <Box className="addressText">
                    <PersonPinCircleOutlinedIcon />
                    {props.selectedAddressDetails ? (
                      <Box>
                        <Typography className="areaInfo">
                          {props.selectedAddressDetails.savedAs}
                        </Typography>
                        <Typography className="locationInfo">
                          {props.selectedAddressDetails.formatted_address}{" "}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="addressText">
                        <Typography>Please add your address..</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box className="arrowBtn">
                    <Button variant="text">
                      <EastIcon />
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </>
          ) : (
            <Box className="logoutCartArea">
              <Box className="logoutCartAreaLeft">
                <Typography>
                  You are not logged in. Please Login or register to place
                  order.
                </Typography>
              </Box>
              <Box className="logoutCartAreaRight">
                <Button
                  type="button"
                  onClick={() => props.navigateToLoginScreen()}
                >
                  login or signup
                </Button>
              </Box>
            </Box>
          ):""}

          {/* Chadhava Items */}
          <Box className="addDevotee chadhavaItems">
            <Box className="devoteeDetails">
              <SubtitleTypography title={`${cartPageTitle} Items`}></SubtitleTypography>
            </Box>
            <Box className="formGroup">
              {selectedChadhvaList &&
                selectedChadhvaList?.length > 0 &&
                selectedChadhvaList?.map((item: any, index: number) => (
                  <Box className="chadhavaItem">
                    <Box className="chadhavaLeft">
                      <Box className="itemImg">
                        {item?.image && <img src={item?.image}></img>}
                      </Box>
                      <Box className="namePrice">
                        <Typography>{item?.name}</Typography>

                        <Typography>
                          {`${
                            item?.priceInfo?.finalPrice
                              ? props.getOverSeasPriceByRateText(
                                  item?.priceInfo?.finalPrice
                                )
                              : "0.00"
                          }`}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="chadhavaRight">
                      <Box className="addItemsBtns">
                        <ButtonGroup className="btnsGroup">
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              if (isCountryDisabledForBooking) {
                                setOpenAlertDialogForBookingNotAllowed(true);
                                return;
                              }
                              decrement(item);
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </Button>
                          <Button variant="contained">{item?.count}</Button>
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              if (isCountryDisabledForBooking) {
                                setOpenAlertDialogForBookingNotAllowed(true);
                                return;
                              }
                              increment(item);
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            {/* <Box className="formGroup">
              <Box className="chadhavaItem">
                <Box className="chadhavaLeft">
                  <Box className="itemImg">
                    <img src={TempleFlag} />
                  </Box>
                  <Box className="namePrice">
                    <Typography>Temple Flag</Typography>
                    <Typography>₹ 159.00</Typography>
                  </Box>
                </Box>
                <Box className="chadhavaRight">
                  <Box className="addItemsBtns">
                    <ButtonGroup className="btnsGroup">
                      <Button variant="contained">
                        <RemoveIcon fontSize="small" />
                      </Button>
                      <Button variant="contained">1</Button>
                      <Button variant="contained">
                        <AddIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </Box>
            </Box> */}
          </Box>
          {/* Chadhava Add-ons */}
          {chadhavaList?.length > 0 && (
            <Box className="addDevotee chadhavaAddOnsItems">
              <Box className="devoteeDetails">
                <SubtitleTypography title={`${cartPageTitle} Add-ons`}></SubtitleTypography>
              </Box>
              <Box className="addOnsSlider">
                <Carousel
                  responsive={CHADHAVA_ADDON_SCROLL_RESPONSIVE}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  keyBoardControl={true}
                  removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                >
                  {chadhavaList?.map((item: any, index: number) => (
                    <Box className="addOnsItems" key={`addOnsItemsX${index}`}>
                      <Box className="addOnsItem">
                        <Box className="imgBlock">
                          <img src={item?.image} alt={item?.name} />
                        </Box>
                        {item?.isMostOfferedTag === true && (
                          <label>Most offered</label>
                        )}
                        <Button
                          className="addBtn"
                          onClick={(e) => {
                            if (isCountryDisabledForBooking) {
                              setOpenAlertDialogForBookingNotAllowed(true);
                              return;
                            }
                            e.preventDefault();
                            addChadhava(item);
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                      <Box className="addOnsDescription">
                        <SubtitleTypography title={item?.name} />
                        <SubtitleTypography
                          title={`${
                            item?.priceInfo?.finalPrice
                              ? props.getOverSeasPriceByRateText(
                                  item?.priceInfo?.finalPrice
                                )
                              : ""
                          }`}
                        />
                      </Box>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Box>
          )}

          {/* Offer Dakshina to Pandit ji */}
          { !prasadEnablity &&(<Box className="addDevotee dakshinaPandit">
            <Box className="devoteeDetails">
              <SubtitleTypography title="Offer Dakshina to Pandit ji"></SubtitleTypography>
            </Box>
            <Box className="offerDakshina">
              <ParagraphTypography title={`Your dakshina will be given to the pandit ji after the ${cartPageTitle} has been presented.`} />
              <Box className="dakshinaCheck">
                <RadioGroup
                  className="checkItems"
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "1rem",
                    alignItems: "center",
                    overflowX: "auto",
                    overflowY: "hidden",
                    whiteSpace: "nowrap",
                    padding: "0.5rem",
                  }}
                >
                  {dakshinaPandit?.map((item: any, index: number) => {
                    const hasChip = item === 31;
                    return (
                      <FormControlLabel
                        key={`customDakshina${index}`}
                        value={item}
                        control={<Radio />}
                        label={
                          <Box>
                            {hasChip && (
                              <Chip
                                label="Mostly Given"
                                color="primary"
                                size="small"
                                className="nwlyAddedbadge"
                              />
                            )}
                            {/* {`₹ ${item}`} */}
                            {props.getOverSeasPriceByRateText(Number(item))}
                          </Box>
                        }
                        className={hasChip ? "chipPresent" : ""}
                        checked={selectedDakshina === item}
                        onClick={() => {
                          props.setPanditDakshina(item);
                          setSelectedDakshina(item);
                          setCustomDakshina(0);
                          setIsEditing(false);
                        }}
                        sx={{
                          minWidth: "100px",
                          textAlign: "center",
                        }}
                      />
                    );
                  })}

                  {isEditing ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        minWidth: "100px",
                        flexShrink: 0,
                        height: "52px",
                      }}
                    >
                      <TextField
                        value={customDakshina}
                        onChange={handleSetCutstomDakshinaChange}
                        onBlur={handleSetCutstomDakshinaBlur}
                        onKeyDown={handleSetCutstomDakshinaKeyDown}
                        variant="outlined"
                        size="small"
                        className="customDakshinaTextArea"
                        inputProps={{
                          style: {
                            textAlign: "center",
                            height: "36px",
                            padding: "8px 0",
                          },
                        }}
                        autoFocus
                        sx={{
                          height: "100%",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        minWidth: "100px",
                        flexShrink: 0,
                        height: "52px",
                      }}
                    >
                      <FormControlLabel
                        value={customDakshina}
                        control={<Radio />}
                        onClick={handleLabelClick}
                        label={`${props.currencySign} ${
                          customDakshina == 0 ? "Custom" : customDakshina
                        }`}
                        className="customDakshinaCardArea"
                        checked={
                          customDakshina > 0 &&
                          selectedDakshina === customDakshina
                        }
                      />
                    </Box>
                  )}
                </RadioGroup>
              </Box>

              <Box className="checkForFuture">
                {/* <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Add this Dakshina amount in all my future offerings"
                  />
                </FormGroup> */}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Checkbox className="checkBoxFuture" defaultChecked />
                  <Typography className="checkForFutureText" component="p">
                    Add this Dakshina amount in all my future offerings
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>) 
          } 
          

          {/* Bill Details */}
          {/* <Box className="addDevotee billDetails">
            <Box className="billTitle">
              <SubtitleTypography title="Bill Details"></SubtitleTypography>
            </Box>
            <Box className="billLists">
              <List>
                <ListItem>
                  <ParagraphTypography title="Chadhava Value" />
                  <ParagraphTypography title="₹ 151.00" />
                </ListItem>
                <ListItem>
                  <ParagraphTypography title="Pandit Dakshina" />
                  <ParagraphTypography title="₹ 21.00" />
                </ListItem>
                <ListItem>
                  <ParagraphTypography title="GST" />
                  <ParagraphTypography title="₹ 27.18" />
                </ListItem>
                <ListItem>
                  <ParagraphTypography title="Total Amount" />
                  <ParagraphTypography title="₹ 199.18" />
                </ListItem>
              </List>
            </Box>
          </Box> */}

          {/* Payment Details */}
          {/* <Box className="addDevotee paymentBlock">
            <Box className="paymentOption">
              <Box className="payOption">
                <FormControl className="paymentType">
                  <InputLabel id="demo-simple-select-label">
                    Pay using
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Pay using"
                  >
                    <MenuItem value={10} className="menuItem" selected>
                      <span>
                        <img src={PhonePe} />
                      </span>{" "}
                      PhonePe
                    </MenuItem>
                    <MenuItem value={20} className="menuItem">
                      <span>
                        <img src={PayTm} />
                      </span>{" "}
                      Paytm
                    </MenuItem>
                    <MenuItem value={30} className="menuItem">
                      <span>
                        <img src={BankImg} />
                      </span>{" "}
                      Net Banking
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="payNow">
                <Button className="btnPayNow">₹ 199.18</Button>
              </Box>
            </Box>
          </Box> */}
        </Box>
      ) : null}

      {/* ----- Puja Booking Checkout Cart ----- */}
      <Box className="cartDetails pujaBookingDetails">
        {/*-------New Design-------------*/}

        <Box className="pujaBookingDetails">
          {pujaEnablity && (
            <>
              <SubtitleTypography
                className="topTitle"
                title="Review Puja Booking Details"
              ></SubtitleTypography>
              <Box className="cardDesign pujaInformation">
                <Stack
                  className="pujaInfoTop"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Box className="pujaNameDate">
                    <Typography className="pujaName">
                      {props?.cartDetails?.pujaDetails?.pujaName}
                    </Typography>
                    <Typography>
                      Puja Performing Date:{" "}
                      {`${props?.cartDetails?.pujaDateTime?.pujaDate}`}
                    </Typography>
                  </Box>
                  <Box className="pujaImage">
                    <img
                      src={props?.cartDetails?.pujaDetails?.pujaBg?.uri}
                      alt=""
                    />
                  </Box>
                </Stack>
                <Box className="pujaInfoBottom">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    className="templeName"
                  >
                    <img src={TempleImg} alt="" />
                    <Typography>{`${props?.cartDetails?.templeDetails?.personalInfo?.name}`}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    className="templeAddress"
                  >
                    <img src={LocationImg} alt="" />
                    <Typography>{`${props?.cartDetails?.templeDetails?.personalInfo?.address?.area}, ${props?.cartDetails?.templeDetails?.personalInfo?.address?.state}`}</Typography>
                  </Stack>
                </Box>
              </Box>
              

              {/* Address */}
              {userDetails ? (
                <>
                  <Box className="cardDesign addressSce">
                    <Typography className="addressTitle">
                      {/* {" "}
                  {props.selectedAddressDetails
                    ? props.selectedAddressDetails.savedAs ||
                    props.selectedAddressDetails.name
                    : "Address"} */}
                      Address
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop="10px"
                      spacing={1}
                      onClick={() => {
                        if (props.selectedAddressDetails) {
                          props.setChooseAddressBox(true);
                        } else {
                          props.navigateToAddressScreen();
                        }
                      }}
                    >
                      <Box className="addressText">
                        <PersonPinCircleOutlinedIcon />
                        {props.selectedAddressDetails ? (
                          <Box>
                            <Typography className="areaInfo">
                              {props.selectedAddressDetails.savedAs}
                            </Typography>
                            <Typography className="locationInfo">
                              {props.selectedAddressDetails.formatted_address}{" "}
                            </Typography>
                          </Box>
                        ) : (
                          <Box className="addressText">
                            <Typography>Please add your address..</Typography>
                          </Box>
                        )}
                      </Box>
                      <Box className="arrowBtn">
                        <Button variant="text">
                          <EastIcon />
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </>
              ) : (
                <Box className="logoutCartArea">
                  <Box className="logoutCartAreaLeft">
                    <Typography>
                      You are not logged in. Please Login or register to place
                      order.
                    </Typography>
                  </Box>
                  <Box className="logoutCartAreaRight">
                    <Button
                      type="button"
                      onClick={() => props.navigateToLoginScreen()}
                    >
                      login or signup
                    </Button>
                  </Box>
                </Box>
              )}
              <Box className="cardDesign devoteeDetails">
                <Typography className="devoteeTitle">
                  Devotee Details
                </Typography>
                <Box className="devoteeNameSec">
                  <Typography className="devoteeNameTitle">
                    Devotee(s) Added
                  </Typography>
                  <Box className="nameArea">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      {props.memberDetails.map((item: any, id: any) => {
                        return (
                          <Grid item md={4} lg={3}>
                            <Chip className="boxColor" label={item.fullName} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Stack
                    className="devoteeBottom"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography className="devoteeCount">
                      X {props.memberDetails.length} Devotees
                    </Typography>
                    <Button
                      variant="contained"
                      endIcon={<EditIcon />}
                      className="editCart"
                      onClick={() => {
                        props.editPujaOptions();
                      }}
                    >
                      Edit Details
                    </Button>
                    <button
                      type="button"
                      className="deleteCart"
                      onClick={() => {
                        //props.clearCart();
                        handleDeleteClick();
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </Stack>
                </Box>
              </Box>

              {/* <Box className="cardDesign offerZone">
                <Typography className="offerZoneTitle">Offers Zone</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  className="couponCodeTop"
                >
                  {props.couponApplyResponse.isApplied ? (
                    <>
                      <Box className="couponApplySec">
                        <img src={couponApplyIcon} alt="" />
                        <Box>
                          <Typography className="couponText">
                            Devotee(s) saved <span>₹{priceData.discount}</span>{" "}
                            with coupon code{" "}
                            <span className="cupCodeText">
                              {
                                props.couponApplyResponse?.couponData
                                  ?.couponCode
                              }{" "}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="removeBtn">
                        <Button
                          variant="text"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            props.setCouponApplyResponse({
                              ...props.couponApplyResponse,
                              isApplied: false,
                              isValid: false,
                            });

                            props.setPriceQuoteForPuja();
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Stack
                      onClick={() => props.setCouponPopUp(true)}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      className="couponCodeBottom"
                    >
                      <Box className="couponApplySec">
                        <img src={couponPercentIcon} alt="" />
                        <Box>
                          <Typography className="couponText">
                            View other coupons
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="arrowBtn">
                        <Button variant="text">
                          <EastIcon />
                        </Button>
                      </Box>
                    </Stack>
                  )}
                </Stack>
              </Box> */}
            </>
          )}
          {pujaEnablity && (
            <Box className="cardDesign prasadCheckbox">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="mainAcceptPrasadBox"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  className="acceptPrasadCheckBox"
                >
                  <Switch
                    color="primary"
                    onChange={handlePrasadCheckboxChange}
                    checked={isPrasad === true}
                  />
                  <Typography>Would you like to receive Prasad</Typography>
                </Stack>
                <Box className="prasadImage">
                  <img src={prasadImg} alt="Prasad" />
                </Box>
              </Stack>
              <Typography className="disclaimer">
                <ErrorOutlineIcon />
                {disclaimerPrasadText.textMessage}
              </Typography>
            </Box>
          )}

          {pujaEnablity && (
            <Box className="cardDesign offerZone">
              <Typography className="offerZoneTitle">Offers Zone</Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                className="couponCodeTop"
              >
                {props.couponApplyResponse.isApplied ? (
                  <>
                    <Box className="couponApplySec">
                      <img src={couponApplyIcon} alt="" />
                      <Box>
                        <Typography className="couponText">
                          Devotee(s) saved{" "}
                          <span>
                            {/* ₹{priceData.discount} */}
                            {props.getOverSeasPriceByRateText(
                              priceData.discount
                            )}
                          </span>{" "}
                          with coupon code{" "}
                          <span className="cupCodeText">
                            {props.couponApplyResponse?.couponData?.couponCode}{" "}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="removeBtn">
                      <Button
                        variant="text"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          props.setCouponApplyResponse({
                            ...props.couponApplyResponse,
                            isApplied: false,
                            isValid: false,
                          });

                          props.setPriceQuoteForPuja();
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Stack
                    onClick={() => props.setCouponPopUp(true)}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    className="couponCodeBottom"
                  >
                    <Box className="couponApplySec">
                      <img src={couponPercentIcon} alt="" />
                      <Box>
                        <Typography className="couponText">
                          View other coupons
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="arrowBtn">
                      <Button variant="text">
                        <EastIcon />
                      </Button>
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Box>
          )}

          <Box className={`cardDesign billDetails ${prasadEnablity? "prasadMbAdd" : ""}`}>
            <Typography className="billDetailsTitle">Bill Details</Typography>
            <Box className="detailList">
              {pujaEnablity && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography>Puja Value </Typography>
                  {/* <Typography>{`₹ ${props?.previousPriceData}/-`}</Typography> */}
                  <Typography>{`${props.getOverSeasPriceByRateText(
                    props?.previousPriceData
                  )}`}</Typography>
                </Stack>
              )}

              {(chadhavaEnablity || prasadEnablity ) && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography>{cartPageTitle} Value</Typography>
                  <Typography className="">
                    {`${props.getOverSeasPriceByRateText(
                      props?.getChadhavaTotalValue() || 0,
                      isOverseas
                    )}`}
                    {/* ₹{props?.getChadhavaTotalValue() || 0} */}
                  </Typography>
                </Stack>
              )}
              {(chadhavaEnablity  ) && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography>Pandit Dakshina</Typography>
                  <Typography className="">
                    {/* {props.currencySign} */}
                    {props.panditDakshina > 0
                      ? props.getOverSeasPriceByRateText(props.panditDakshina)
                      : props.getOverSeasPriceByRateText(selectedDakshina) || 0}
                  </Typography>
                </Stack>
              )}

              {priceData.discount  ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography className="couponCode">
                    Coupon Applied{" "}
                    <span>
                      ({props.couponApplyResponse?.couponData?.couponCode})
                    </span>
                  </Typography>
                  <Typography className="couponCodeDiscount">
                    {/* {`₹ ${priceData.discount}/-`} */}
                    - {props.getOverSeasPriceByRateText(priceData.discount)}
                  </Typography>
                </Stack>
              ) : null}
              {/* {pujaEnablity ? (<Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="listItem"
              >
                <Typography>GST</Typography>
                <Typography>{`₹ ${priceData.gst}/-`}</Typography>
              </Stack>) : ""} */}

              {priceData.discount ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography>Total</Typography>
                  <Typography className="totalAmountCut">
                    {props.getOverSeasPriceByRateText(props?.previousPriceData)}
                    {/* ₹{Math.ceil(props?.previousPriceData || 0)} */}
                  </Typography>
                </Stack>
              ) : null}

              {/* { chadhavaEnablity && priceData.discount ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="listItem"
                >
                  <Typography>Total</Typography>
                  <Typography className="totalAmountCut">
                    ₹{priceData?.actualFinalPrice || 0}
                  </Typography>
                </Stack>
              ) : null} */}

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="listItem"
              >
                <Typography>
                  <span>Final Payment</span>
                </Typography>
                <Typography>
                  {/* <span>{`₹ ${
                    priceData.finalPrice
                      ? Math.ceil(Number(priceData.finalPrice))
                      : "0.00"
                  }/-`}</span> */}
                  {`${
                    (chadhavaEnablity || prasadEnablity )
                      ? isOverseas
                        ? getSymbolWithPrice(getChadhavaFinalPaymentValue())
                        : props.getOverSeasPriceByRateText(
                            props.getChadhavaFinalPaymentValue(),
                            isOverseas
                          )
                      : props.getOverSeasPriceByRateText(priceData.finalPrice)
                  }/-`}
                </Typography>
              </Stack>
            </Box>
          </Box>

          {userDetails ? !prasadEnablity &&(
            <Box className="cardDesign userDetails">
              <Typography className="userDetailsTitle">User Details</Typography>
              <Box className="userDetailList">
                <Typography>
                  {props.userPersonalInfo.firstName}{" "}
                  {props.userPersonalInfo.lastName}
                </Typography>
                <Typography>
                  {" "}
                  {props?.userDetails?.phoneNumber?.substring(0, 7)}XXXX
                  {props?.userDetails?.phoneNumber?.substring(
                    props?.userDetails?.phoneNumber?.length - 2,
                    props?.userDetails?.phoneNumber?.length
                  )}
                </Typography>
                {/* <Typography>{props.userPersonalInfo.email}</Typography> */}
              </Box>
              <Stack
                direction="row"
                justifyContent="flex-start"
                sx={{
                  alignItems: {
                    xs: "flex-start",
                    md: "center",
                  },
                }}
                spacing={1}
                className="mailAcceptDetail"
              >
                <Checkbox className="checkBox" />
                <Typography>
                  I want to receive transaction and puja updates on WhatsApp and
                  email.
                </Typography>
              </Stack>
            </Box>
          ) : null}
          {/* Cancellation Policy */}
          {
            !prasadEnablity && (
              <Box className="cardDesign cancellationPolicy">
                <Typography className="cancelPolicyTitle">
                  Cancellation Policy
                </Typography>
                <Box className="policyText">
                  <Typography>
                    Orders can be cancelled 24 hours prior starting the puja, after
                    that no refund for cancellations will be made.
                  </Typography>
                </Box>
              </Box>
            )
          }

          {/* Proceed to Payment BTN */}

          <Box className="paymentBtn">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                {`${
                  (chadhavaEnablity || prasadEnablity )
                    ? isOverseas
                      ? getSymbolWithPrice(getChadhavaFinalPaymentValue())
                      : props.getOverSeasPriceByRateText(
                          props.getChadhavaFinalPaymentValue(),
                          isOverseas
                        )
                    : props.getOverSeasPriceByRateText(priceData.finalPrice)
                }/-`}
              </Typography>

              {userDetails ? (
                <Button
                  variant="contained"
                  onClick={(e: any) => {
                    if (isCountryDisabledForBooking) {
                      setOpenAlertDialogForBookingNotAllowed(true);
                      return;
                    }
                    let orderAmount = isOverseas
                      ? calculateOverSeasPrice(
                          props?.priceBreakup?.finalPrice,
                          props?.rate,
                          "number"
                        )
                      : props?.priceBreakup?.finalPrice;
                    if (
                      (isOverseas && orderAmount < 1) ||
                      (!isOverseas && orderAmount < 100)
                    ) {
                      setOpenPriceErrorDialog(true);
                      return;
                    }

                    props.placeOrder(e);
                  }}
                >
                  Proceed to Payment
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={(e: any) => props.navigateToLoginScreen()}
                >
                  Login/Register
                </Button>
              )}
            </Stack>
          </Box>
        </Box>

        <Dialog
          className="startPostInput"
          onClose={() => {
            setChooseAddressBox(false);
            if (selectedAddressDetails?.countryCode !== "IN") {
              setIsPrasad(false);
            }
          }}
          open={props.chooseAddressBox}
        >
          <ChooseAddress
            userPersonalInfo={props.userPersonalInfo}
            changeDeliverytAddress={props.changeDeliverytAddress}
            setChooseAddressBox={props.setChooseAddressBox}
          />
        </Dialog>

        {/* <Dialog
        className="Orderresult"
        onClose={() => props.setOrderCreatedScreen(false)}
        open={props.orderCreatedScreen}
      >
        <Box style={{ width: "20em", height: "15em",padding:"1em" }}>
          <Typography style={{marginTop:"1em"}}>
          Your order Created Successfully
          </Typography>
          <Typography style={{fontWeight: "bold"}}>
          Order Id : {props.orderResultDetails}
          </Typography>
         <Button onClick={()=>{dispatch(setCartItems([]));}}>Ok</Button>
        </Box>
      </Dialog> */}

        <Dialog
          className="warnMsg"
          onClose={() =>
            props.setErrorPopUp({ isError: false, errorMessages: [] })
          }
          open={props.errorPopup.isError}
        >
          <Box className="warnCard">
            <Typography>
              <ErrorOutlineRoundedIcon />
              Please take action on below items before submiting
            </Typography>
            {props.errorPopup.errorMessages.map((item: any, id: any) => {
              if (item.errType === "addressNotGiven") {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{ margin: "20px 0 10px" }}
                  >
                    <Typography className="warnItem">{item.message}</Typography>
                    <Button onClick={() => props.navigateToAddressScreen()}>
                      Add Address +
                    </Button>
                  </Stack>
                );
              } else if (item.errType === "memberNotSelected") {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{ margin: "20px 0 10px" }}
                  >
                    <Typography className="warnItem">{item.message}</Typography>
                    <Button onClick={() => props.navigateToAddMembersScreen()}>
                      Add Members +
                    </Button>
                  </Stack>
                );
              } else {
                return null;
              }
              // return <Box><Typography className="warnItem">
              //   {item.message}</Typography>

              //   <Button></Button>
              //   </Box>;
            })}
            <Button
              sx={{ marginTop: "30px" }}
              onClick={() =>
                props.setErrorPopUp({ isError: false, errorMessages: [] })
              }
            >
              Ok
            </Button>
          </Box>
        </Dialog>

        <CouponCodeDialog
          open={props.couponPopup}
          onClose={() => props.setCouponPopUp(false)}
          allActiveCoupons={props.allActiveCoupons}
          setCouponCodeData={props.setCouponCodeData}
          checkValidityOfcoupon={props.checkValidityOfcoupon}
          couponApplyResponse={props.couponApplyResponse}
          couponCodeType={chadhavaEnablity ? "CHADHAVA" : "PUJA"}
          // handleSetCouponApplyResponse={handleSetCouponApplyResponse}
        />

        <DeleteConfirmDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDelete}
          msg="Do you really want to clear everything from your cart?"
        />

        <AlertDialog
          open={outSideIndiaMessagePopup}
          onClose={() => {
            setOutSideIndiaMessagePopup(false);
          }}
          message="Selected Address is outside India. We do not ship out of India"
        />

        {/* <Dialog
        className="errorMsg"
        onClose={() => props.setBookingFailedPopUp(false)}
        open={props.bookingFailedPopup}
      >
        <Typography>Sorry for inconvenience. Error happened while booking the Puja. Please try again after sometime !</Typography>
        <Button onClick={() => props.setBookingFailedPopUp(false)}>Ok</Button>
      </Dialog> */}
        <AlertDialog
          open={openPriceErrorDialog}
          onClose={handleCloseAlertDialogForPrice}
          message={`Bookings are not permitted for below ${
            isOverseas ? props?.currencySign : "₹"
          }${isOverseas ? 1 : 100}.`}
        />
        <AlertDialog
          open={openAlertDialogForBookingNotAllowed}
          onClose={handleCloseAlertDialogForBookingNotAllowed}
          message={
            [orderType?.chadhava,orderType?.puja].includes(cartDetails?.orderType) ?  POP_UP_MESSAGE?.bookingErrorMessagePujaChadhava  : POP_UP_MESSAGE?.bookingErrorMessagePrasad
          }
        />
      </Box>
    </Box>
  );
}

export default ReviewCartView;

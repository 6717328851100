import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/x-date-pickers/AdapterDateFns";

import { Outlet, useNavigate } from "react-router-dom";
import SubtitleTypography from "../../../components/typography/SubtitleTypography";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { ADD_DEVOTEE_LABEL } from "../../../utils/ConstantsMessages";

import {
  getDateFormatedForPujaDetails,
  getDevoteeSerial,
} from "../../../utils/HelperFunctions";
import { useEffect, useState } from "react";
import React from "react";
import { orderType } from "../../../utils/Constants";
import WhatsappNumberInput from "../../../components/WhatsappNumberInput/WhatsappNumberInput";

function AddDevoteeDetailsView(props: any) {
  const navigate = useNavigate();
  const { cartInfo } = props;
  const singleCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;
  let maxMemberAllowed = props.membersInfo?.length || 0;
  if (
    (singleCartData?.pujaPackage?.maxMemberAllowed ||
      singleCartData?.pujaDetails?.maxMemberAllowed) &&
    singleCartData?.orderType == orderType.puja
  ) {
    maxMemberAllowed = Number(
      singleCartData?.pujaPackage?.maxMemberAllowed ||
        singleCartData?.pujaDetails?.maxMemberAllowed
    );
  }

  let previousUserBookList = props?.prevUserGroup || [];

  // --- For Add Optional Details Modal ---
  const [open, setOpen] = React.useState(false);
  const [validationMessage, setValidationMessage] = useState<string>(""); // State for validation message
  const prasadEnablity =  singleCartData?.orderType === orderType.prasad ? true : false;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState(null);

  const handleGenderChange = (event: any) => {
    setGender(event.target.value);
  };
  
  return (
    <Box className="firstStep AddDevoteeDetails">
      <Box className="chadhavaDetails">
        {singleCartData?.orderType === orderType.puja ? (
          <SubtitleTypography title="Puja Details"></SubtitleTypography>
        ) : (
          <SubtitleTypography
            title={`${
              singleCartData?.orderType === orderType.prasad
                ? "Prasad"
                : "Chadhava"
            } Details`}
          ></SubtitleTypography>
        )}
        {
          !prasadEnablity && (
            <Box className="infoDevotee">
              <ErrorOutlineOutlinedIcon /> You can add maximum {maxMemberAllowed}{" "}
              devotee at a time.
            </Box>
          )
        }
        <Box className="addDevotee">
          <SubtitleTypography title="Add Devotee Details"></SubtitleTypography>
          <Box className="formGroup">
            {singleCartData?.orderType === orderType.puja && (
              <Box className="formField lightOpa">
                <label>{ADD_DEVOTEE_LABEL?.pujaNameLabel} </label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={singleCartData?.pujaDetails?.pujaName}
                  variant="standard"
                />
              </Box>
            )}

            <Box className="formField lightOpa">
              <label>{ADD_DEVOTEE_LABEL?.templeNameLabel}</label>
              <TextField
                fullWidth
                id="standard-helperText"
                value={singleCartData?.templeDetails?.personalInfo?.name}
                variant="standard"
              />
            </Box>
            {singleCartData?.orderType !== orderType.prasad && (
              <Box className="formField lightOpa">
                <label>{ADD_DEVOTEE_LABEL?.pujaDateLabel}</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={getDateFormatedForPujaDetails(
                    singleCartData?.pujaDateTime?.pujaDate
                  )}
                  variant="standard"
                />
              </Box>
            )}
            {
              !prasadEnablity && (
                <Box className="formField">
                  <label>{ADD_DEVOTEE_LABEL?.whatsAppNumberLabel}</label>
                  <WhatsappNumberInput
                    setWhatsAppNumber={props?.setWhatsAppNumber}
                    whatsAppNumber={ props?.whatsAppNumber}
                    error={!!props?.whatsAppNumberError}
                    setSelectedDialCode={props?.setSelectedDialCode}
                    selectedDialCode={props?.selectedDialCode}
                    selectedCountryForWhatapp={props?.selectedCountryForWhatapp}
                    setSelectedCountryForWhatapp={
                      props?.setSelectedCountryForWhatapp
                    }
                  />
                  <FormHelperText
                    className="errorText"
                    style={{
                      display:
                        props?.whatsAppNumberError &&
                        (props?.whatsAppNumber === undefined ||
                          props?.whatsAppNumber?.length < 10)
                          ? "block"
                          : "none",
                    }}
                  >
                    {ADD_DEVOTEE_LABEL?.whatsAppNumberErrorMessage}
                  </FormHelperText>
                </Box>
              )
            }

            {
              !prasadEnablity && (
                <Box className="formField">
                <Button
                  className="addDevt"
                  onClick={() => props.setPopUpDevoteePreviousBooking(true)}
                >
                  <AddCircleRoundedIcon />{" "}
                  {ADD_DEVOTEE_LABEL?.addDevoteeFromPreviousBookingLabel}
                </Button>
              </Box>
            )
            }
            { !prasadEnablity && props.membersInfo.map((item: any, id: any) => {
              return (
                <div>
                  <Box className="formField mbAdd">
                    <label>
                      Full Name of {getDevoteeSerial(id)} Devotee{" "}
                      {id === 0 ? "*" : ""}
                    </label>
                    <TextField
                      fullWidth
                      name="fullName"
                      id="fullName"
                      value={item.fullName}
                      variant="standard"
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (
                          value === "" ||
                          /^[a-zA-Z][a-zA-Z ]*$/.test(value)
                        ) {
                          props.updateMemberInfo(id, {
                            ...item,
                            fullName: value,
                          });
                        }
                      }}
                      error={!!props.fullNameError}
                    />
                    <FormHelperText
                      className="errorText"
                      style={{
                        display:
                          props.fullNameError && id < 1 ? "block" : "none",
                      }}
                    >
                      {ADD_DEVOTEE_LABEL?.devoteeNameError}
                    </FormHelperText>
                  </Box>
                </div>
              );
            })}
            {/* for prsad only */}

            {
              prasadEnablity && (
                <Box className="formField">
                <Button
                  className="addDevt"
                  onClick={() => props.setPopUpDevoteePreviousBooking(true)}
                >
                  <AddCircleRoundedIcon />{" "}
                  {ADD_DEVOTEE_LABEL?.addDevoteeFromPreviousBookingLabel}
                </Button>
              </Box>
            )
            }
            { prasadEnablity && props.membersInfo.map((item: any, id: any) => {
              return (
                <div>
                  <Box className="formField mbAdd">
                    <label>
                      Full Name of Devotee{" "}
                      {id === 0 ? "*" : ""}
                    </label>
                    <TextField
                      fullWidth
                      name="fullName"
                      id="fullName"
                      value={item.fullName}
                      variant="standard"
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (
                          value === "" ||
                          /^[a-zA-Z][a-zA-Z ]*$/.test(value)
                        ) {
                          props.updateMemberInfo(id, {
                            ...item,
                            fullName: value,
                          });
                        }
                      }}
                      error={!!props.fullNameError}
                    />
                    <FormHelperText
                      className="errorText"
                      style={{
                        display:
                          props.fullNameError && id < 1 ? "block" : "none",
                      }}
                    >
                      {ADD_DEVOTEE_LABEL?.devoteeNameError}
                    </FormHelperText>
                  </Box>
                </div>
              );
            })}

           {
              prasadEnablity && (
                <Box className="formField">
                  <label>{ADD_DEVOTEE_LABEL?.whatsAppNumberLabel}</label>
                  <WhatsappNumberInput
                    setWhatsAppNumber={props?.setWhatsAppNumber}
                    whatsAppNumber={ props?.whatsAppNumber}
                    error={!!props?.whatsAppNumberError}
                    setSelectedDialCode={props?.setSelectedDialCode}
                    selectedDialCode={props?.selectedDialCode}
                    selectedCountryForWhatapp={props?.selectedCountryForWhatapp}
                    setSelectedCountryForWhatapp={
                      props?.setSelectedCountryForWhatapp
                    }
                  />
                  <FormHelperText
                    className="errorText"
                    style={{
                      display:
                        props?.whatsAppNumberError &&
                        (props?.whatsAppNumber === undefined ||
                          props?.whatsAppNumber?.length < 10)
                          ? "block"
                          : "none",
                    }}
                  >
                    {ADD_DEVOTEE_LABEL?.whatsAppNumberErrorMessage}
                  </FormHelperText>
                </Box>
              )
            }

            {singleCartData?.orderType !== orderType.prasad && (
              <>
                <Box className="formField">
                  <label>{ADD_DEVOTEE_LABEL?.gotramLabel}</label>
                  <TextField
                    fullWidth
                    name="Gotram"
                    id="Gotram"
                    value={props?.gotram}
                    variant="standard"
                    onChange={(e: any) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z ]/g,
                        ""
                      );
                      props.addGotram(newValue);
                    }}
                  />
                </Box>

                <Box className="formField">
                  <FormGroup className="formCheck">
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={ADD_DEVOTEE_LABEL?.gotramCheckboxLabel}
                    />
                  </FormGroup>
                </Box>
              </>
            )}

            {singleCartData?.orderType === orderType.puja && (
              <Box className="formField">
                <label>{ADD_DEVOTEE_LABEL?.manokamnaLabel}</label>

                <TextField
                  className="monokamnaDetails"
                  id="standard-basic"
                  placeholder="Enter here"
                  multiline
                  rows={4}
                  fullWidth
                  value={props?.manokamna}
                  onChange={(e: any) => {
                    props.addmanokamna(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* ---- Previous Booking Devotee Modal ---- */}
      <Dialog
        className="startPostInput"
        onClose={() => props.setPopUpDevoteePreviousBooking(false)}
        open={props.popUpDevoteePreviousBooking}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "14px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, pb: 1, fontSize: "17px", fontWeight: "600" }}
        >
          {ADD_DEVOTEE_LABEL?.addDevoteeDialogTitle}
        </DialogTitle>
        <Button
          aria-label="close"
          color="primary"
          onClick={() => props.setPopUpDevoteePreviousBooking(false)}
          sx={{
            position: "absolute",
            right: 0,
            top: 10,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon />
        </Button>
        <DialogContent sx={{ p: 1, width: "400px", maxWidth: "100%" }}>
          <Box sx={{ display: "flex", marginLeft: "15px" }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {previousUserBookList.map((item: any, id: any) => {
                  return (
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={item.fullName}
                      onClick={(e) => {
                        e.preventDefault();
                        props.selectPrevGroupMember(item);
                        console.log("Item selcetd", item);
                        // Write function to add that devotee to next blank area
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "#d56939",
              color: "#fff",
              m: 1,
              mb: "12px",
              padding: "8px",
              borderRadius: "30px",
              "&:hover": {
                backgroundColor: "rgb(149, 73, 39)",
              },
            }}
          >
            {ADD_DEVOTEE_LABEL?.addDevoteeDialogContinueButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        className="saveAndContinueBtn"
        onClick={() => props.proceedToCart()}
      >
        {ADD_DEVOTEE_LABEL?.saveAndContinueButtonLabel}
      </Button>
    </Box>
  );
}

export default AddDevoteeDetailsView;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import AIGurujiViewReadOnly from "../../Views/AIGurujiView/AIGurujiViewReadOnly";
import CloseIcon from "@mui/icons-material/Close";
import "./ChatHistory.scss";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface ChatHistoryProps {
  open: boolean;
  onClose: () => void;
  selectedChatHistory: any;
  exportCSV:() => void;
}
const ChatHistory: React.FC<ChatHistoryProps> = ({
  open,
  onClose,
  selectedChatHistory,
  exportCSV
}) => {
  const transcribeAudio = () => {
    
  };

 const [chats, setChats] = useState([]) 
  useEffect(() => {
    if(selectedChatHistory?.chatHistory){
      setChats(selectedChatHistory?.chatHistory)
    }
  }, [selectedChatHistory])
  

  console.log("selectedChatHistory",selectedChatHistory);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        className="gurujiChatListDialog"
      >
        <DialogTitle className="chatListTopTitle">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="topItem"
          >
            <span>Chat History of {selectedChatHistory?.userName || " "}</span>
            <Box>
               <Button onClick={exportCSV} className="exportBtn" variant="contained">
                <FileDownloadIcon
                />{" "}
                Export
              </Button>
            <IconButton onClick={onClose} className="topCloseIcon">
              <CloseIcon />
            </IconButton>
            </Box>
           
          </Stack>
        </DialogTitle>

        <DialogContent className="gurujiChatListContent">
          {chats && chats?.length > 0 ? (
            <AIGurujiViewReadOnly
              chatMessage={chats}
              transcribeAudio={transcribeAudio}
            />
          ) : (
            <Typography variant="body1" color="textSecondary">
              No chat history available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions className="chatListBottomArea">
          <Button onClick={onClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChatHistory;

import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { labels } from "../../../../utils/ConstantsMessages";
import "./AIGurujiRashiCard.scss";

const AIGurujiRashiCard: React.FC<any> = ({ sunSign, moonSign, selectedLang }) => {
  const [langLabels, setlangLabels] = useState<any>(
    labels[selectedLang === "EN" ? "EN" : "HI"]
  );

  useEffect(() => {
    if (selectedLang) {
      setlangLabels(labels[selectedLang === "EN" ? "EN" : "HI"]);
    }
  }, [selectedLang]);


  return (
    <TableContainer component={Paper} className="rashiCardTable">
      <Table>
        <TableHead>
          {/* <TableRow>
            <TableCell>Rashi Name</TableCell>
            <TableCell>Position</TableCell>
          </TableRow> */}
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><BedtimeIcon className="moonIcon" />{langLabels.AstrologyMoonSignText}</TableCell>
            <TableCell>{moonSign}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><LightModeIcon className="sunIcon" />{langLabels.AstrologySunSignText}</TableCell>
            <TableCell>{sunSign}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AIGurujiRashiCard;
import React, { useState, useRef, useEffect } from "react";
import verifytick from "../../assets/verify-tick.png";
import share from "../../assets/share.png";
import whitelike from "../../assets/whitelike.png";
import "./PrasadDetailsView.scss";
import GoogleMap from "../../components/map/GoogleMap";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsBanner from "../../components/banner/TempleDetailsBanner";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextArea from "../../components/textarea/Textarea";
import Typography from "@mui/material/Typography";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ChadhavaBanner from "../../assets/chadhava-banner.jpg";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import MorPankh from "../../assets/morpankh.png";
import TempleFlag from "../../assets/temple-flag.png";

import RemoveIcon from "@mui/icons-material/Remove";
import { AddIcon } from "@chakra-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChadavaAddDialog from "../../components/dialog/ChadavaAddDialog";
import ShareLink from "../../components/sharelink/sharelink";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  UPCOMING_PUJA_CAROUSAL_RESPONSIVE,
  POPULAR_TEMPLE_CAUROSAL_RESPONSIVE,
  FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
} from "../../utils/Constants";
import TestimonialCard from "../../components/card/Testimonial/TestimonialCard";
// import "./Chadhava.scss";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import RoundedButton from "../../components/button/RoundedButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { typography } from "@chakra-ui/system";
import CalculateOverSeasPrice from "../../components/PriceComponent/PriceComponent";
import { disclaimerText, POP_UP_MESSAGE } from "../../utils/ConstantsMessages";
import { calculateOverSeasPrice } from "../../utils/HelperFunctions";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertDialog from "../../components/dialog/AlertDialog";

function PrasadDetailsView(props: any) {
  const {
    templeProfilePic,
    templeDetails,
    templePersonalInfo,
    handleIncrement,
    handleDecrement,
    filteredprasadList,
    navigateToSeeVideoView,
    getAvilableChadhavaDate,
    totalPrice,
    totalPriceDisplay,
    setIsDateTimePicker,
    isSingleDatePuja,
    isDateTimePicker,
    shouldDisableDate,
    setPujaDateAndTime,
    pujaDateAndTime,
    cartInfo,
    setPopupItemAlreadyAddedInCart,
    preprocessingAndProceedToAddmemberScreen,
    popupItemAlreadyAddedInCart,
    overSeasRate,
    currencySymbols,
    isOverseas,
    isCountryDisabledForBooking,
    isLoading,
  } = props;

  const templeProfilePicture = templeProfilePic();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // Chadhava Add Popup
  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [
    openAlertDialogForBookingNotAllowed,
    setOpenAlertDialogForBookingNotAllowed,
  ] = useState<any>(false);

  const handleButtonClick = (el: any) => {
    setSelectedItem(el);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const [urlToShare, setUrlToShare] = useState<string>("");
  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?prasadOftemple=${templeDetails?.nameKey}`
    );
  }, [templeDetails]);

  const [templeName, setTempleName] = useState<string>("");
  const [templeAddress, setTempleAddress] = useState<string>("");
  const [templeDescriptions, setTempleDescriptions] = useState<string>("");
  const [prasadBenefits, setParsadBenefits] = useState<any>([]);
  const { city, state } = templePersonalInfo?.address || {};

  useEffect(() => {
    if (templePersonalInfo?.name) {
      setTempleName(templePersonalInfo?.name);
    }
    if (templePersonalInfo?.descriptions) {
      setTempleDescriptions(templePersonalInfo?.descriptions);
    }
  }, [templePersonalInfo]);

  useEffect(() => {
    if (templeDetails?.prasadBenefits) {
      setParsadBenefits(templeDetails?.prasadBenefits);
    }
  }, [templeDetails]);

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const increment = (item: any) => {
    handleIncrement(item);
  };

  const decrement = (item: any) => {
    handleDecrement(item);
  };

  const addChadhavaItem = (item: any) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      props.addChadhavaItem(item);
    }
  };

  const [isAboveFooter, setIsAboveFooter] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footerArea");
      const button = document.querySelector(".sticky-button");
      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const buttonRect = button.getBoundingClientRect();
        if (
          footerRect.top < window.innerHeight &&
          footerRect.bottom >= buttonRect.bottom
        ) {
          setIsAboveFooter(true);
        } else {
          setIsAboveFooter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let filteredArray = filteredprasadList || [];

  const handleCloseAlertDialogForBookingNotAllowed = () => {
    setOpenAlertDialogForBookingNotAllowed(false);
  };

  const handleSetIsDateTimePicker = (isOpen: boolean) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      if (cartInfo && cartInfo.length > 0) {
        setPopupItemAlreadyAddedInCart(true);
      } else {
        preprocessingAndProceedToAddmemberScreen();
      }
    }
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={templeProfilePicture} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="chadhavaNameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "46px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${templeName}`} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "black !important" }}
                            primary={`${city}, ${state}`}
                          />
                        </ListItem>
                        <ListItem>
                          <Typography className="disclaimerText">
                            <ErrorOutlineIcon />
                            {disclaimerText?.textMessagePrasad}
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary title="About “Temple Dekho”"></SubtitleTypographyPrimary>
                <Typography>
                  Please watch our feature video to discover the capabilities of
                  the <span>Temple Dekho</span> app.
                </Typography>
                <Button
                  onClick={() => navigateToSeeVideoView()}
                  variant="contained"
                  className="ChadhavSeeVideoBtn"
                >
                  See Video
                </Button>
              </Box> */}

              <Box className="chadhavaItemTitle">
                <SubtitleTypographyPrimary title="Prasad Packages"></SubtitleTypographyPrimary>
              </Box>
              {isLoading === false &&
                filteredArray?.map((item: any, index: number) => (
                  <Box
                    className={`chadhavaDetailsBlock chadhavaItems ${
                      item.disabled === true ? "boxDisable" : ""
                    }`}
                    key={"PrasadItem" + index}
                  >
                    <Box className="chadhavaItem">
                      <Box className="chadhavaLeft">
                        <Typography>
                          {item?.name}
                          {item?.isMostOfferedTag && (
                            <span className="offerItem">Most offered</span>
                          )}
                        </Typography>

                        {item?.disabled === true && (
                          <Typography sx={{ color: "red" }}>
                            {getAvilableChadhavaDate()}
                          </Typography>
                        )}
                        <ParagraphTypography title={item?.itemDescription} />
                        <TitleTypography
                          title={` ₹ ${item?.priceInfo?.finalPrice}`}
                        />
                        {/* <CalculateOverSeasPrice  inrPrice={item?.priceInfo?.finalPrice} priceFor="chadhava"/> */}
                      </Box>
                      <Box className="chadhavaRight">
                        <Box className="itemImg">
                          <img src={item?.image}></img>
                        </Box>
                        {item?.count === 0 ? (
                          <Box className="btnAdd">
                            <Button
                              className="chadhavaAdd"
                              variant="outlined"
                              onClick={(e) => {
                                e.preventDefault();
                                addChadhavaItem(item);
                              }}
                            >
                              ADD
                            </Button>
                          </Box>
                        ) : (
                          <Box className="addItemsBtns">
                            <ButtonGroup className="btnsGroup">
                              <Button
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrement(item);
                                }}
                              >
                                <RemoveIcon fontSize="small" />
                              </Button>
                              <Button variant="contained">{item?.count}</Button>
                              <Button
                                variant="contained"
                                onClick={(e) => increment(item)}
                              >
                                <AddIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              {isLoading === true && (
                <Box className="loadingCircle">
                  <CircularProgress color="primary" />
                </Box>
              )}
              {/* <Box className="pujaBenefits">
                <SubtitleTypographyPrimary title="Benefits of Prasad"></SubtitleTypographyPrimary>
                {prasadBenefits.map((item: any, id: any) => {
                  return (
                    <Box className="benefitsCard">
                      <SubtitleTypography
                        title={`${item.name}`}
                      ></SubtitleTypography>
                      <Box className="benefitCardImg">
                        <img src={`${item.url}`} alt=""></img>
                      </Box>
                      {item.description && (
                        <ParagraphTypography
                          title={`${item.description}`}
                        ></ParagraphTypography>
                      )}
                    </Box>
                  );
                })}
              </Box> */}

              <Box className="overViewText1">
                <SubtitleTypographyPrimary title="Temple Details"></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${templeDescriptions}`}
                ></ParagraphTypography>
              </Box>

              <Box className="prasadFaq">
                <SubtitleTypographyPrimary title="Frequently Asked Questions"></SubtitleTypographyPrimary>
                <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange1("panel1")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      What is the Prasad Delivery Service?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Prasad Delivery Service allows you to receive sacred prasad
                      from your chosen temple, even if you cannot visit or book
                      a puja. It’s a simple way to receive divine blessings and
                      stay connected to your spiritual practices, even if you’re
                      unable to participate in a full puja.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange1("panel2")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      Which temples are available for Prasad Delivery?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      We offer a curated list of revered temples from across
                      India, where you can receive prasad. You can explore the
                      full list of temples available for Prasad Delivery on our
                      website or app.{" "}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange1("panel3")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      What items are included in the Prasad package?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      The prasad package typically includes blessed offerings
                      from the temple, such as sweets, flowers, kumkum, and
                      other sacred items used in the deity's worship. Each
                      temple has its own unique customs, so the contents of the
                      package may vary.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange1("panel4")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      When will I receive my Prasad?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Delivery timelines may vary depending on the temple’s
                      location and the courier services used. However, we strive
                      to deliver your prasad within 5-7 business days. Rest
                      assured, we prioritize careful handling and prompt
                      delivery, ensuring your blessed offerings reach you as
                      quickly as possible.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange1("panel5")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      Is the Prasad authentic from the temple and hygienic?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Yes, the prasad is authentic and prepared with the utmost
                      reverence. It is carefully offered to the deity in the
                      temple and then packaged with great care, following all
                      hygiene protocols to ensure safety and quality. We
                      prioritize both authenticity and hygiene to bring you a
                      pure, sacred experience.
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              <Box
                className="chadhavaContinueBtn desktopOnly sticky-button"
                sx={{ position: `${isAboveFooter ? "absolute" : "fixed"}` }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>
                    {" "}
                    {`₹ ${totalPriceDisplay ? totalPriceDisplay : totalPrice}`}
                    /-
                  </Typography>
                  {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                  <Button
                    className={
                      totalPrice === 0
                        ? "desktopContinueBtnGrey"
                        : "desktopContinueBtn"
                    }
                    onClick={() => handleSetIsDateTimePicker(true)}
                    disabled={totalPrice === 0}
                  >
                    {" "}
                    Book Prasad
                  </Button>
                </Stack>
              </Box>

              <Box className="blessContinueChadhava"></Box>
              <Box className="blessContinue">
                <Box className="viewBless">
                  <img src={viewBless}></img>
                </Box>
                <Box className="continueCart">
                  <Box className="continueCenterBlock">
                    <Box className="continueLt">
                      {` ₹ ${
                        totalPriceDisplay ? totalPriceDisplay : totalPrice
                      }`}
                      /-
                      {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                    </Box>

                    <Box className="continueRt">
                      <Button
                        type="button"
                        disabled={totalPrice === 0}
                        className={
                          totalPrice === 0 ? "continueBtnDsbl" : "continueBtn"
                        }
                        onClick={() => {
                          if (isSingleDatePuja) {
                            //bookedSingleDatePuja()
                          } else {
                            handleSetIsDateTimePicker(true);
                          }
                        }}
                      >
                        Book Prasad
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
        {/* <Dialog
          className="dateTimingSelection"
          onClose={() => setIsDateTimePicker(false)}
          open={isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitleTypography title="Select Date">Select Date</TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => setIsDateTimePicker(false)}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 6,
                  fontSize: "12px",
                  color: "#d56939",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack> */}

        {/* <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={shouldDisableDate}
                  disablePast={true}
                  onChange={(value: any) => {
                    setPujaDateAndTime({
                      ...pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box> */}
        {/* <Box className="btnContinue">
              <RoundedButton
                title="Continue"
                // disabled={
                //   pujaDateAndTime && pujaDateAndTime?.pujaDate ? false : true
                // }
                onClick={() => {
                  setIsDateTimePicker(false);
                  if (cartInfo && cartInfo.length > 0) {
                    setPopupItemAlreadyAddedInCart(true);
                  } else {
                    preprocessingAndProceedToAddmemberScreen();
                  }
                  //
                }}
              ></RoundedButton>
            </Box> */}
        {/* </Box>
        </Dialog> */}

        <Dialog
          // className="dateTimingSelection"
          onClose={() => setPopupItemAlreadyAddedInCart(false)}
          open={popupItemAlreadyAddedInCart}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>{POP_UP_MESSAGE?.replacePujaOrChadhavaMessage}</Typography>
            <Box sx={{ textAlign: "end", mt: 1 }}>
              <Button onClick={() => setPopupItemAlreadyAddedInCart(false)}>
                No
              </Button>
              <Button
                onClick={() => preprocessingAndProceedToAddmemberScreen()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <AlertDialog
        open={openAlertDialogForBookingNotAllowed}
        onClose={handleCloseAlertDialogForBookingNotAllowed}
        message={
          "Oops! Bookings are not permitted in your country. We apologize for the inconvenience. Please note that bookings are accepted only in India."
        }
      />
    </Box>
  );
}

export default PrasadDetailsView;

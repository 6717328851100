import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { adminChadhawaViewLabel } from "../../utils/ConstantsMessages";
import MultipleSelectBeneFits from "../AutoComplete/MultipleSelectBeneFits";

interface ChadawaBenefitDialogProps {
  open: boolean;
  onClose: () => void;
  benefitsList: any[];
  selectedBenefitsOptions: any[];
  handleBenefitsSelect: (selected: any[]) => void;
  handleBenefitsSubmit: () => void;
}

const ChadawaBenefitDialog: React.FC<ChadawaBenefitDialogProps> = ({
  open,
  onClose,
  benefitsList,
  selectedBenefitsOptions,
  handleBenefitsSelect,
  handleBenefitsSubmit,
}) => {
  const handleSubmit = () => {
    handleBenefitsSubmit();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{adminChadhawaViewLabel.SelectBenefits}</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <MultipleSelectBeneFits
          options={benefitsList}
          label="Benefits List"
          placeholder="Choose Benefits"
          onChange={handleBenefitsSelect}
          alreadySelectedOptions={selectedBenefitsOptions}
        ></MultipleSelectBeneFits>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChadawaBenefitDialog;

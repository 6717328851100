import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Box,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import morpankh from "../../../assets/morpankh.png";
import "./ChadhawaView.scss";
import ChadhawaFormModal from "./ChadhawaForm";
import MultipleSelectBeneFits from "../../../components/AutoComplete/MultipleSelectBeneFits";
import {
  elGR,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { CalendarPicker } from "@mui/lab";
import MultiDateCalendar from "../../../Screen/MultiDateCalendar/MultiDateCalendar";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import { adminChadhawaViewLabel } from "../../../utils/ConstantsMessages";
//import { Button } from "react-bootstrap";
import ChadawaBenefitDialog from "../../../components/dialog/ChadawaBenefitDilog";
import ChadhawaWeightageDilog from "../../../components/dialog/ChadhawaWeightageDilog";

interface TempleListProps {
  temples: any[];
  setSelectedTemple: any;
  chadhavaList: any[];
  saveChadhawaItem: (temple: any) => void;
  benefitsList: any[];
  handleBenefitsSelect: any;
  selectedBenefitsOptions: any[];
  handleBenefitsSubmit: () => void;
  selectedTempleId: string;
  deleteChadhavaItem:(item:any) => void;
  selectedTemple:any;
  handleTempleWeightageSubmit: (weightage:any) => void;
}

const ChadhawaView: React.FC<TempleListProps> = ({
  temples,
  setSelectedTemple,
  chadhavaList,
  saveChadhawaItem,
  benefitsList,
  handleBenefitsSelect,
  selectedBenefitsOptions,
  handleBenefitsSubmit,
  selectedTempleId,
  deleteChadhavaItem,
  selectedTemple,
  handleTempleWeightageSubmit
}) => {
  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChadavaDateModalOpen, setIsChadavaDateModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedChadhavaItem,setSelectedChadhavaItem] = useState<any>(null)
  const [isWeightageDialogOpen, setIsWeightageDialogOpen] = useState(false); 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //console.log("chadhavaList", chadhavaList);
  }, [chadhavaList]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSM = () => {
    setOpenSuccessMessage(false);
  };

  const handleEditItem = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleAddItem = () => {
    setSelectedItem(null);
    setIsModalOpen(true);
  };

  const handleAddChadavaItem = () => {
    setIsChadavaDateModalOpen(true);
  };

  const handleSaveChadhawaItem = (temple: any) => {
    saveChadhawaItem(temple);
    setIsModalOpen(false);
  };

  const handleBenefitsSubmitForm = (data: any) => {
    handleBenefitsSubmit();
    setOpen(false);
  };
  
  const handleDeleteClick = (item:any) => {
    setIsDialogOpen(true);
    setSelectedChadhavaItem(item);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedChadhavaItem(null);
  };

  const handleConfirmDelete = () => {
     deleteChadhavaItem(selectedChadhavaItem)
     setIsDialogOpen(false);
  };
const handleWeightageDialogOpen = () => {
    setIsWeightageDialogOpen(true);
  };

  const handleWeightageDialogClose = () => {
    setIsWeightageDialogOpen(false);
  };
  return (
    <Box className="pageContainer chadhavaAdmin">
      {/* <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="templeSelectDpDn"
        mt={2}
        spacing={2}
      >
        <Typography className="dpDnText">Chadhawa Items for temple</Typography>
        <Autocomplete
          options={temples}
          getOptionLabel={(option) => option?.personalInfo?.name}
          style={{ width: 300 }}
          onChange={(event, newValue) => setSelectedTemple(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose Temples" variant="outlined" />
          )}
        />
      </Stack> */}



      <Box sx={{ textAlign: "center" }}>
        <Typography className="dpDnText">{adminChadhawaViewLabel.title}</Typography>
      </Box>
      <Stack
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Autocomplete
          options={temples}
          getOptionLabel={(option) => option?.personalInfo?.name}
          style={{ width: 300 }}
          onChange={(event, newValue) => setSelectedTemple(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose Temples" variant="outlined" />
          )}
        />
        <Box>
          {selectedTempleId && selectedTempleId?.length > 0 && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="flex-end"
              spacing={2}
            >
                <Button
                variant="outlined"
                onClick={handleWeightageDialogOpen}
              >
                {adminChadhawaViewLabel.weightage}
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleAddItem();
                }}
              >
               {adminChadhawaViewLabel.ChadhavaItem}
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleClickOpen();
                }}
              >
               {adminChadhawaViewLabel.ChadhavaBenefits}
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>

      {chadhavaList && chadhavaList?.length > 0 && (
        <List className="chadhavaItemListAdmin">
          {chadhavaList && chadhavaList?.map((temple, index) => (
            <ListItem
              key={temple.id}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a", 
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={temple?.image}
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 2,
                    borderRadius: "10px",
                    border: "1px solid #cdcdcd",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                className="listText"
                primary={temple?.name}
                secondary={
                  <>
                    <span className="templeDesc">
                      {temple?.itemDescription}
                    </span>

                    <span className="templePujaAmount">
                      {temple?.acctualPrice ? temple?.acctualPrice : 0} रुपये
                    </span>
                  </>
                }
              />
              <IconButton
                sx={{
                  background: `linear-gradient(-45deg, rgba(0, 0, 0, 0) 33%, hsla(0, 0%, 100%, 0.5) 50%, rgba(0, 0, 0, 0) 66%), 
                  linear-gradient(-45deg, #d8a820, #d66f35)`,
                  backgroundSize: "250% 250%, 100% 100%",
                  animation: "gradient 15s ease infinite",
                  "@keyframes gradient": {
                    "0%": { backgroundPosition: "0% 50%" },
                    "50%": { backgroundPosition: "100% 50%" },
                    "100%": { backgroundPosition: "0% 50%" },
                  },
                  color: "white",
                  marginRight: "8px",
                }}
                aria-label="edit"
                onClick={(e) => {
                  handleEditItem(temple);
                }}
              >
                <EditIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  transition: "0.5s ease",
                  "&:hover": {
                    backgroundColor: "#af1919",
                  },
                }}
                aria-label="delete"
                onClick={e=>{e.preventDefault(); handleDeleteClick(temple)}}
              >
                <DeleteIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}

       <ChadawaBenefitDialog
        open={open}
        onClose={onClose}
        benefitsList={benefitsList}
        selectedBenefitsOptions={selectedBenefitsOptions}
        handleBenefitsSelect={handleBenefitsSelect}
        handleBenefitsSubmit={handleBenefitsSubmit}
      />

      <ChadhawaFormModal
        isOpen={isModalOpen}
        temple={selectedItem}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveChadhawaItem}
      />
      <DeleteConfirmDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        msg={adminChadhawaViewLabel.deleatemassage}
      />
      <ChadhawaWeightageDilog
        open={isWeightageDialogOpen}
        onClose={handleWeightageDialogClose}
        onSubmit={handleTempleWeightageSubmit}
        selectedTemple={selectedTemple}
      />
    </Box>
  );
};

export default ChadhawaView;

import React, { useEffect, useState } from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchFilter.scss";

interface SearchFilterProps {
  handleSearchSpPujalist: (searchText: string) => void;
}


const SearchFilter: React.FC<SearchFilterProps> = ({handleSearchSpPujalist}) => {
  const [searchText, setSearchText] = useState("");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
     
  };

  useEffect(() => {
    handleSearchSpPujalist(searchText)
  }, [searchText])
  

  return (
    <Box className="search-container">
      <TextField
        variant="outlined"
        value={searchText}
        onChange={handleInputChange}
        placeholder="Search..."
        className="search-input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchFilter;

import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, TextField, Typography } from "@mui/material";
import "./CountryAutocomplete.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CountrySelection from "../CountrySelection/CountrySelection";

const CountryAutocomplete = ({
  setIsOtpScreen,
  sendOtp,
  setPhone,
  preOtpErrorMsg,
  setPreOtpErrorMsg,
  selectedDialCode,
  setSelectedDialCode,
}: any) => {
  const [openCountryDialog, setOpenCountryDialog] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(
    "https://flagcdn.com/w320/in.png"
  );
  const { countryCode } = useSelector((state: any) => state.auth);
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");

  let maxAllowedLength = 13;
  const handleClickOpenCountryDialog = () => {
    setOpenCountryDialog(true);
  };

  useEffect(() => {
    if (countryCode && countryCode.length > 0) {
      const filteredCountry = countryCode?.filter(
        (item: any) => item?.code === "IN"
      );
      setSelectedDialCode(filteredCountry[0].dial_code);
      setSelectedCountry(filteredCountry[0].code);
      setSelectedFlag(
        `https://flagcdn.com/w320/${
          filteredCountry[0] && filteredCountry[0].code
            ? filteredCountry[0].code.toLowerCase()
            : "in"
        }.png`
      );
    }
  }, [countryCode]);
  useEffect(() => {
    setPhone(mobileNumber);
  }, [mobileNumber]);

  const handleClose = (value: {
    flag: string;
    dialCode: string;
    code: string;
  }) => {
    setOpenCountryDialog(false);

    if (typeof value !== "undefined") {
      console.log("Setting selected values", value);
      setSelectedFlag(value.flag);
      setSelectedDialCode(value.dialCode);
      setSelectedCountry(value.code);
    }
  };

  const handleProceedClick = (e: any) => {
    e.preventDefault();
    // Only allow numbers in input
    if (/^\d*$/.test(mobileNumber)) {
      // Check if the number is exactly 10 digits
      if (mobileNumber.length === 10) {
        setError(""); // No error if length is 10
      } else {
        setError("Mobile number should be exactly 10 digits.");
        return;
      }
    } else {
      setError("Mobile number should be exactly 10 digits and numeric only.");
      return;
    }
    const phoneWithCode = `${mobileNumber}`;
    if (phoneWithCode?.trim().length > 0 && selectedDialCode.length > 0) {
      sendOtp(selectedDialCode);
      // setIsOtpScreen(true);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleProceedClick(event);
    }
  };
  const handleGuestClick = () => {
    navigate("/");
  };

  return (
    <Box className="loginCard">
      <Typography variant="h1" className="newAccountTitle">
        Create a <br /> New Account
      </Typography>
      <Box className="line"></Box>
      <Box className="leftAlignText">
        <Typography variant="h6" className="subTitleText">
          Spiritual Experience with <br />
          <span className="boldText">Temple Dekho</span>
        </Typography>
        <Typography className="loginPhNoText">
          LOGIN/SIGNUP WITH YOUR MOBILE NUMBER
        </Typography>
      </Box>

      <Box className="countryInputArea">
        <button className="flagImgBtn" onClick={handleClickOpenCountryDialog}>
          <img src={selectedFlag} alt="Selected Country Flag" />
          <ArrowDropDownIcon className="dropdownIcon" />
        </button>

        <CountrySelection
          open={openCountryDialog}
          onClose={handleClose}
          selectedDialCode={selectedDialCode}
          selectedCountryCode={selectedCountry}
        />

        <TextField
          className="phNoInput"
          label="Mobile Number"
          fullWidth
          variant="standard"
          value={`${selectedDialCode || ""} ${mobileNumber || ""}`}
          onChange={(e) => {
            setPreOtpErrorMsg("");
            setError("");
            setMobileNumber(
              e.target.value.replace(selectedDialCode || "", "").trim()
            );
          }}
          inputProps={{
            inputMode: "numeric", // Displays a numeric keyboard on mobile devices
            pattern: "[0-9]*", // Ensures only numeric input
            maxLength: maxAllowedLength,
          }}
          error={!!error || preOtpErrorMsg}
          helperText={error ? error : preOtpErrorMsg ? preOtpErrorMsg : ""}
          onKeyDown={handleKeyDown} // Attach the keydown event for ENTER from keyboard
        />
      </Box>

      <Box className="bottomButtonGroup">
        <Button
          className="proceedBtn"
          onClick={handleProceedClick}
          disabled={mobileNumber?.length === 0}
        >
          PROCEED
        </Button>

        <Button className="guestBtn" onClick={handleGuestClick}>
          CONTINUE AS A GUEST
        </Button>
      </Box>
    </Box>
  );
};

export default CountryAutocomplete;

import React, { useEffect, useState } from "react";
import {
    Box,
    Switch,
    TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";



const TempleView: React.FC<any> = (props) => {
    const [temples, setTemples] = useState<any>([]);
    const [templePosition,setTemplePosition] = useState<any>(null);
    const [selectedTempleId, setSelectedTempleId] = useState<any>(null)

    useEffect(() => {

        setTemples(props.temples)

    }, [props.temples])



    const handleChadhavaToggle = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
       props.handleChadhavaToggle(temple)
    };

    const handleDisableToggle = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
        props.handleDisableToggle(temple)
       
    };

    const handleIsClosedBooking = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
         props.handleIsClosedBooking(temple)
       
    };

    const handleTogglePrasad = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
        props.handleTogglePrasadForTemple(temple)
    };

    const handleTemplePositionChange = (row: any, newValue: string) => {
        const updatedTemples = temples.map((temple: any) => {
            if (JSON.parse(row.data)?.id === temple.id) {
                return { ...temple, position: newValue };
            }
            return temple;
        });
        setTemples(updatedTemples)
        setTemplePosition(newValue)
        setSelectedTempleId(JSON.parse(row?.data)?.id)
    };

    const onBlurPosition = ()=>{
        props.handlePositonSubmitFortemple(selectedTempleId,templePosition)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'No.',
            width: 90,
            renderCell: (param: any) => `${param.row.id}`,
        },
        {
            field: 'nameKey',
            headerName: 'Temple Name',
            width: 450
        },
        {
            field: 'isChadhavaEnabled',
            headerName: 'Chadhava Enabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) =>{e.preventDefault(); handleChadhavaToggle(params?.row)} }
                />
            ),
        },
        {
            field: 'isClosed',
            headerName: 'Temple Disabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) => {e.preventDefault(); handleDisableToggle(params?.row)}}
                />
            ),
        },
        {
            field: 'isClosedBooking',
            headerName: 'Booking Puja Disabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) => {e.preventDefault(); handleIsClosedBooking(params?.row)}}
                />
            ),
        },
        {
            field: 'isPrasadEnabled',
            headerName: 'Prasad Delivary Enabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) => {e.preventDefault(); handleTogglePrasad(params?.row)}}
                />
            ),
        },
        {
            field: 'position',
            headerName: 'Temple Weightage',
            width: 180,
            renderCell: (params: any) => (
                <TextField
                    variant="standard"
                    fullWidth
                    value={params.value}
                    onChange={(e:any) => handleTemplePositionChange(params.row, e.target.value)}
                    onBlur={onBlurPosition}
                />
            ),
        },
    ];

    const rows: any[] =  Array.isArray(temples) ? temples?.map((temple: any, index = 0) => ({
        id: index + 1,
        nameKey: temple.personalInfo.name,
        isChadhavaEnabled: temple.isChadhavaEnabled,
        isClosed: temple.isClosed,
        isClosedBooking: temple.isClosedBooking,
        data:JSON.stringify(temple),
        position: temple.position,
        isPrasadEnabled: temple?.isPrasadEnabled || false,
    })) : [];

    
    return (
        <Box className="pageContainer" mt={10}>
           
            <Box className="couponCodeView" sx={{ bgcolor: "#fff" }}>

                <DataGrid
                    className="couponDataGrid"
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                                page: 0,
                            },
                        },
                    }}
                    getRowId={(row) => row.id}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    autoHeight

                />
            </Box>

        </Box>
    );
};

export default TempleView;

import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, TextField } from "@mui/material";
import "./WhatsappNumberInput.scss";
import {  useSelector } from "react-redux";

import CountrySelection from "../CountrySelection/CountrySelection";

const WhatsappNumberInput = (props: any) => {
  const {
    setWhatsAppNumber,
    whatsAppNumber,
    selectedDialCode,
    setSelectedDialCode,
    selectedCountryForWhatapp,
    setSelectedCountryForWhatapp,
  } = props;
  const [openCountryDialog, setOpenCountryDialog] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(
    "https://flagcdn.com/w320/in.png"
  );
  const { countryCode } = useSelector((state: any) => state.auth);
  let maxAllowedLength = 10;
  useEffect(() => {
    if (
      countryCode &&
      countryCode.length > 0 &&
      !selectedCountryForWhatapp
    ) {
      const filteredCountryCode = countryCode.filter(
        (item: any) => item.code === "IN"
      );
      setSelectedDialCode(filteredCountryCode[0].dial_code);
      setSelectedCountryForWhatapp(filteredCountryCode[0].code);
      setSelectedFlag(
        `https://flagcdn.com/w320/${
          filteredCountryCode[0] && filteredCountryCode[0].code
            ? filteredCountryCode[0].code.toLowerCase()
            : "in"
        }.png`
      );
    }
    if (countryCode && countryCode.length > 0 && selectedCountryForWhatapp) {
      const filteredCountryCode = countryCode.filter(
        (item: any) => item.code === selectedCountryForWhatapp
      );
      setSelectedDialCode(filteredCountryCode[0].dial_code);
      setSelectedCountryForWhatapp(filteredCountryCode[0].code);
      setSelectedFlag(
        `https://flagcdn.com/w320/${
          filteredCountryCode[0] && filteredCountryCode[0].code
            ? filteredCountryCode[0].code.toLowerCase()
            : "in"
        }.png`
      );
    }
  }, [countryCode]);

  const handleClickOpenCountryDialog = () => {
    setOpenCountryDialog(true);
  };
  const handleClose = (value: {
    flag: string;
    dialCode: string;
    code: string;
  }) => {
    setOpenCountryDialog(false);

    if (typeof value !== "undefined") {
      setSelectedFlag(value.flag);
      setSelectedDialCode(value.dialCode);
      setSelectedCountryForWhatapp(value.code);
    }
  };

  return (
    <Box className="whatsappInputCard">
      <Box className="countryInputArea">
        <button className="flagImgBtn" onClick={handleClickOpenCountryDialog}>
          <img src={selectedFlag} alt="Selected Country Flag" />
          <ArrowDropDownIcon className="dropdownIcon" />
        </button>

        <CountrySelection
          selectedCountryCode={selectedCountryForWhatapp}
          selectedDialCode={selectedDialCode}
          open={openCountryDialog}
          onClose={handleClose}
        />

        <TextField
          className="phNoInput"
          fullWidth
          variant="standard"
          value={whatsAppNumber}
          onChange={(e) => {
            let value = e.target.value.replace(/\D/g, "").trim();
            setWhatsAppNumber(value);
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            maxLength: maxAllowedLength,
          }}
        />
      </Box>
    </Box>
  );
};

export default WhatsappNumberInput;

import {
  mainCategories,
  upcomingPujas,
  popularTemples,
  posts,
  featuresToDispaly,
  orderType,
} from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import templedetailbanner from "../../assets/postprofile3.jpg";
import { useEffect, useState } from "react";
import {
  UserService,
  TempleService,
  PanchangService,
  PostService,
  AuthService,
} from "td_server";
import { Link, useLocation } from "react-router-dom";
import LazyLoadingScreen from "../../Screen/LazyLoading/LazyLoadingScreen";
import {
  getTempleDetailsById,
  getTempleDetailsByNameKey,
} from "../../store/actions/templeAction";
import {
  calculateOverSeasPrice,
  dayNameToNumber,
  getDateFormatedForMUISelection,
  getOneImageForTemple,
  uid,
} from "../../utils/HelperFunctions";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import ChadhavaDetailsView from "../../Views/Chadhava/ChadhavaDetailsView";
import dayjs from "dayjs";
import { getChadhavaForTempleList } from "../../store/actions/ChadhawaAction";
import { updateCartAction } from "../../store/actions/cartAction";
import { forEach } from "lodash";

function ChadhavaDetailsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { selectedTempleDetails } = useSelector((state: any) => state.temple);
  const { templeChadhavaList } = useSelector((state: any) => state.chadhawa);
  const [localRestrictionChadavaDate, setLocalRestrictionChadavaDate] =
    useState<string[]>([]);
  const [templePersonalInfo, setTemplePersonalInfo] = useState<any>({});
  const [templeId, setTempleId] = useState<string>("");
  const [chadhavaList, setChadhavaList] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPriceDisplay, setTotalPriceDisplay] = useState<number>(0);
  const [selectedChadhva, setSelectedChadhva] = useState<any>([]);
  const [isDateTimePicker, setIsDateTimePicker] = useState(false);
  const [filteredChadhavaList, setFilteredChadhavaList] = useState(false);
  const [firstSelectedItemDate, setFirstSelectedItemDate] = useState("");
  const [pujaDateAndTime, setPujaDateAndTime] = useState<any>(null);
  const [popupItemAlreadyAddedInCart, setPopupItemAlreadyAddedInCart] =
    useState(false);
  const { cartInfo } = useSelector((state: any) => state.cart);
  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const {
    rate = 1,
    currencySymbols = "₹",
    isOverseas = false,
    platformFeeForChadhava,
  } = priceConversionState.priceConversionRate || {};
  const {isLoading} = priceConversionState;

  useEffect(() => {
    if (templeChadhavaList?.length > 0) {
      const updatedResponse = templeChadhavaList.map((item: any) => ({
        ...item,
        count: 0,
        disabled: false,
      }));
      setChadhavaList(updatedResponse);
    }
    console.log(`templeChadhavaList`, templeChadhavaList);
  }, [templeChadhavaList]);

  useEffect(() => {
    const templePersonalInfo =
      selectedTempleDetails && selectedTempleDetails.personalInfo
        ? selectedTempleDetails.personalInfo
        : {};
    setTemplePersonalInfo(templePersonalInfo);
  }, [selectedTempleDetails]);

  useEffect(() => {
    const templeId = location.pathname.split("/")[2];
    setTempleId(templeId);
  }, []);

  const templeProfilePic = () => {
    let imageArrayForTemple =
      templePersonalInfo &&
      templePersonalInfo.media &&
      templePersonalInfo.media.pictures &&
      templePersonalInfo.media.pictures.length
        ? templePersonalInfo.media.pictures
        : [];
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple);
    return templeProfilePic;
  };

  const goToBookPujaScreen = () => {
    navigate("/templePujaList/" + templeId);
  };

  useEffect(() => {
    if (templeId) {
      dispatch(getTempleDetailsByNameKey(templeId));
      dispatch(
        getChadhavaForTempleList(
          templeId,
          isOverseas,
          platformFeeForChadhava,
          rate
        )
      );
    }
  }, [templeId, priceConversionState.priceConversionRate]);

  const navigateToPujaStepsView = () => {
    navigate("/pujaSteps");
  };
  const navigateToSeeVideoView = () => {
    navigate("/pujaVideo");
  };

  const handleSetChadhawaPrice = (item: any) => {
    const price = item?.count * item?.acctualPrice;
    setTotalPrice((prevPrice) => prevPrice + price);
    setSelectedChadhva((prev: any) => [item, ...prev]);

    setChadhavaList((prev: any) =>
      prev.map((chadhava: any) => (chadhava.id === item.id ? item : chadhava))
    );
  };
  const handleIncrement = (item: any) => {
    setChadhavaList((prev: any) =>
      prev.map((chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count + 1;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      })
    );
  };

  const getCalculateTotalPrice = (items: any[]): number => {
    return items.reduce((total, item) => {
      const itemTotal = item.count * Number(item?.priceInfo?.finalPrice);
      return total + itemTotal;
    }, 0);
  };
  const getCalculateTotalPriceDisp = (items: any[]): any => {
    const chadhavaTotalValue = items.reduce((total, item) => {
      const itemTotal = Number(
        item.count * Number(item?.priceInfo?.finalPriceOv)
      ).toFixed(2);

      return Number((total + Number(itemTotal)).toFixed(2));
    }, 0);

    console.log("chadhavaTotalValue", items);
    return chadhavaTotalValue;
  };
  useEffect(() => {
    const selectedChadhava = chadhavaList.filter(
      (item: any) => item?.count > 0
    );
    setTotalPrice(getCalculateTotalPrice(selectedChadhava));
    setTotalPriceDisplay(getCalculateTotalPriceDisp(selectedChadhava));
  }, [chadhavaList]);

  const preprocessingAndProceedToAddmemberScreen = () => {
    const formattedDateToSave = getDateFormatedForMUISelection(
      pujaDateAndTime?.pujaDate?.toString()
    );
    let dateTimeData = {
      pujaDate: formattedDateToSave,
      pujaTime: "10:30AM",
    };

    let cartDataPayload: any = {
      id: uid(),
      members: [],
      selectedChadhvaList: filteredChadhavaList,
      templeDetails: selectedTempleDetails,
      pujaDateTime: dateTimeData,
      price: totalPrice,
      orderType: orderType.chadhava,
    };

    let newCartData = [];
    newCartData.push(cartDataPayload);
    dispatch(updateCartAction(newCartData));
    navigate("/checkoutV2/addDevotee");
  };

  const getCommonDates = (list: any): string[] => {
    if (list.length === 0) return [];
    const firstDates = list[0]?.restrictionChadavaDate;
    return firstDates.filter((dayOrDate: any) =>
      list.every((obj: any) => obj.restrictionChadavaDate?.includes(dayOrDate))
    );
  };

  useEffect(() => {
    const localDate: any[] = [];
    if (needToSetLocalDate) {
      const selectedChadhavaItem = [...chadhavaList].filter(
        (item: any) => item?.count > 0
      );
      if (selectedChadhavaItem?.length > 0) {
        let newDate: string[] = [];

        selectedChadhavaItem.forEach((el: any) => {
          if (newDate?.length > 0) {
            const ndate: string[] = newDate.filter((item) =>
              el?.restrictionChadavaDate.includes(item)
            );
            newDate = ndate;
          } else {
            newDate = el?.restrictionChadavaDate;
          }
        });

        setLocalRestrictionChadavaDate(newDate);

        const filteredArray = chadhavaList?.filter((obj: any) =>
          obj?.restrictionChadavaDate
            ? obj?.restrictionChadavaDate?.some((item: any) =>
                newDate.includes(item)
              )
            : true
        );
        const ids = filteredArray.map((item: any) => item.id);
        const filteredArrayItem: any = [...chadhavaList].map((item: any) => {
          if (ids.includes(item.id)) {
            return { ...item, disabled: false };
          } else {
            return { ...item, disabled: true };
          }
        });
        const sortedArray = filteredArrayItem.sort(
          (a: any, b: any) => Number(a.disabled) - Number(b.disabled)
        );
        setFilteredChadhavaList(sortedArray);
        return;
      } else {
        setLocalRestrictionChadavaDate([]);
        setFilteredChadhavaList(chadhavaList);
        return;
      }
    }

    if (localRestrictionChadavaDate.length > 0) {
      const filteredArray = chadhavaList?.filter((obj: any) =>
        obj?.restrictionChadavaDate
          ? obj?.restrictionChadavaDate?.some((item: any) =>
              localRestrictionChadavaDate.includes(item)
            )
          : true
      );
      const ids = filteredArray.map((item: any) => item.id); //get id for selected chadhava item

      //Disable unmached items
      const filteredArrayItem: any = [...chadhavaList].map((item: any) => {
        if (ids.includes(item.id)) {
          return {
            ...item,
            disabled: false,
          };
        } else {
          return {
            ...item,
            disabled: true,
          };
        }
      });
      const sortedArray = filteredArrayItem.sort(
        (a: any, b: any) => Number(a.disabled) - Number(b.disabled)
      );
      setFilteredChadhavaList(sortedArray);
    } else {
      setFilteredChadhavaList(chadhavaList);
    }
  }, [chadhavaList]);

  const addChadhavaItem = (el: any) => {
    if (localRestrictionChadavaDate?.length > 0) {
      const newDates = localRestrictionChadavaDate.filter((item) =>
        el?.restrictionChadavaDate.includes(item)
      );
      setLocalRestrictionChadavaDate(newDates);
    } else {
      setLocalRestrictionChadavaDate(el?.restrictionChadavaDate);
    }
    handleIncrement(el);
  };
  const [needToSetLocalDate, setNeedToSetLocalDate] = useState(false);
  const handleDecrement = (item: any) => {
    setChadhavaList((prev: any) =>
      prev.map((chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count > 0 ? chadhava.count - 1 : 0;
          if (updatedCount === 0) {
            setNeedToSetLocalDate(true);
          }
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      })
    );
  };

  const getAvilableChadhavaDate = (): string => {
    return (
      "This chadhava item is not available on the given date " +
        localRestrictionChadavaDate?.toString() || ""
    );
  };

  const shouldDisableDate = (date: any): any => {
    const restrictionDate: any = localRestrictionChadavaDate;
    if (
      typeof restrictionDate === "undefined" ||
      restrictionDate?.length === 0
    ) {
      return false;
    }

    const dayNames: string[] = restrictionDate?.filter((item: any) =>
      isNaN(Date.parse(item))
    );

    const selectedDate = dayjs();
    const twoDaysBeforeSelected = selectedDate.add(3, "day");
    if (date.isBefore(twoDaysBeforeSelected, "day")) {
      return true;
    }

    const specificDates: Date[] = restrictionDate
      ?.filter((item: any) => !isNaN(Date.parse(item)))
      ?.map((dateString: any) => new Date(dateString));
    const disableWeekDays: number[] = dayNames
      ?.map(dayNameToNumber)
      ?.filter((day) => day !== null) as number[];
    const day: number = date.day();
    if (disableWeekDays?.includes(day)) {
      return false;
    }

    return !specificDates?.some(
      (disabledDate) =>
        date?.date() === disabledDate?.getDate() &&
        date?.month() === disabledDate?.getMonth() &&
        date?.year() === disabledDate?.getFullYear()
    );
  };

  return (
    <ParentView>
      <ChadhavaDetailsView
        templeDetails={selectedTempleDetails}
        templePersonalInfo={templePersonalInfo}
        goToBookPujaScreen={goToBookPujaScreen}
        templeProfilePic={templeProfilePic}
        navigateToPujaStepsView={navigateToPujaStepsView}
        navigateToSeeVideoView={navigateToSeeVideoView}
        chadhavaList={chadhavaList}
        selectedChadhva={selectedChadhva}
        totalPrice={totalPrice}
        isDateTimePicker={isDateTimePicker}
        setIsDateTimePicker={setIsDateTimePicker}
        pujaDateAndTime={pujaDateAndTime}
        setPujaDateAndTime={setPujaDateAndTime}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        filteredChadhavaList={filteredChadhavaList}
        preprocessingAndProceedToAddmemberScreen={
          preprocessingAndProceedToAddmemberScreen
        }
        shouldDisableDate={shouldDisableDate}
        firstSelectedItemDate={firstSelectedItemDate}
        getAvilableChadhavaDate={getAvilableChadhavaDate}
        addChadhavaItem={addChadhavaItem}
        cartInfo={cartInfo}
        popupItemAlreadyAddedInCart={popupItemAlreadyAddedInCart}
        setPopupItemAlreadyAddedInCart={setPopupItemAlreadyAddedInCart}
        overSeasRate={rate}
        currencySymbols={currencySymbols}
        totalPriceDisplay={totalPriceDisplay}
        isOverseas={isOverseas}
        isCountryDisabledForBooking={
          priceConversionState?.isCountryDisabled ?? false
        }
        isLoading={isLoading}
      />
    </ParentView>
  );
}

export default ChadhavaDetailsScreen;
function getNextDayOfWeek(date: string[]): string {
  throw new Error("Function not implemented.");
}

import PromotionalPujaView from "../../Views/Puja/PromotionalPujaView";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, Navigate, useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { getTempleDetailsById } from "../../store/actions/templeAction";
import {
  setSelectedPuja,
  setSelectedTempleDetailsForPuja,
} from "../../store/slices/pujaSlice";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import {
  getPromotionalPujaListById,
  getPromotionalPujaListByNameKey,
} from "../../store/actions/pujaAction";
function PromotioanlPujaScreen(props: any) {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { promotionalList, slectedPromotionalPujaOrganisations } = useSelector(
    (state: any) => state.puja
  );
  const [promotionalPujaDetails, setPromotionalPujaDetails]: any =
    useState(null);

  const [selectedPujaOrg, setSelectedPujaOrg]: any = useState(null);
  useEffect(() => {
    let keyType = props?.keyType;
    if (keyType && keyType === "id") {
      const promotionalPujaId = location.pathname.split("/")[2];
      dispatch(getPromotionalPujaListById(promotionalPujaId));
    } else {
      const params = new URLSearchParams(location.search);
      const promotionalPujaId = params.get("pujaNameKey");

      dispatch(getPromotionalPujaListByNameKey(promotionalPujaId)).then();
    }
  }, []);

  useEffect(() => {
    if (
      slectedPromotionalPujaOrganisations &&
      slectedPromotionalPujaOrganisations.length > 0
    ) {
      if (
        slectedPromotionalPujaOrganisations &&
        slectedPromotionalPujaOrganisations.length > 0 &&
        slectedPromotionalPujaOrganisations[0].hasOwnProperty("version")
      ) {
        setSelectedPujaOrg(slectedPromotionalPujaOrganisations[0].templeList);
        setPromotionalPujaDetails(slectedPromotionalPujaOrganisations[0]);
      } else {
        setSelectedPujaOrg(
          JSON.parse(JSON.stringify(slectedPromotionalPujaOrganisations))
        );
      }
    }
  }, [slectedPromotionalPujaOrganisations]);

  useEffect(() => {
    let keyType = props?.keyType;
    if (keyType && keyType === "id") {
      const promotionalPujaId = location.pathname.split("/")[2];
      const selectedPromotionalPujaDetailsFiltered =
        promotionalList && promotionalList.length > 0
          ? promotionalList.filter(function (item: any) {
              return item.id === promotionalPujaId;
            })
          : [];
      var promotionalPujaDetailsTemp = null;
      if (
        selectedPromotionalPujaDetailsFiltered &&
        selectedPromotionalPujaDetailsFiltered.length > 0
      ) {
        promotionalPujaDetailsTemp = selectedPromotionalPujaDetailsFiltered[0];

        setPromotionalPujaDetails(promotionalPujaDetailsTemp);
      }
    } else {
      const params = new URLSearchParams(location.search);
      const promotionalPujaId = params.get("pujaNameKey");

      const selectedPromotionalPujaDetailsFiltered =
        promotionalList && promotionalList.length > 0
          ? promotionalList.filter(function (item: any) {
              return item.pujaNameKey === promotionalPujaId;
            })
          : [];

      var promotionalPujaDetailsTemp = null;
      if (
        selectedPromotionalPujaDetailsFiltered &&
        selectedPromotionalPujaDetailsFiltered.length > 0
      ) {
        promotionalPujaDetailsTemp = selectedPromotionalPujaDetailsFiltered[0];

        if (
          promotionalPujaDetailsTemp &&
          promotionalPujaDetailsTemp.length > 0 &&
          promotionalPujaDetailsTemp[0].hasOwnProperty("version")
        ) {
          setPromotionalPujaDetails(promotionalPujaDetailsTemp[0].templeList);
        } else {
          setPromotionalPujaDetails(promotionalPujaDetailsTemp);
        }
      }
    }
  }, [promotionalList]);

  const onClickParticipate = (templeId: any, pujaId: any) => {
    navigate(`/templePujaInfo?templeNameKey=${templeId}&pujaNameKey=${pujaId}`);
  };
  return (
    <ParentView>
      <PromotionalPujaView
        promotionalPujaDetails={promotionalPujaDetails}
        slectedPromotionalPujaOrganisations={selectedPujaOrg}
        onClickParticipate={onClickParticipate}
      />
    </ParentView>
  );
}

export default PromotioanlPujaScreen;

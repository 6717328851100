import { useDispatch, useSelector } from "react-redux";
import CouponView from "../../Views/Admin/Coupon/CouponView";
import ParentView from "../../Views/PrarentView/ParentView";
import { getAllAIChatsByDateRange } from "../../store/actions/gurujiChatAction";
import { useEffect, useState } from "react";
import AIGurujiChatList from "../../Views/Admin/AIGuruji/AIGurujiChatList";
import { Box } from "@mui/material";
import moment from "moment";
import ChatHistory from "../../components/dialog/ChatHistory";
import { setLoading } from "../../store/slices/authSlice";

function AIGurujiChatScreen() {
  const dispatch: any = useDispatch();
  const {
    isDownloading,
    allChatHistory,
    FChatHistoryFDBlastDoc,
    chatHistoryTotalCount,
    isLoading,
  } = useSelector((state: any) => state.gurujiChat);
  const [allChats, setAllChats] = useState<any>([]);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [selectedChatHistory, setSelectedChatHistory] = useState<any>(null);
  const [pageCache, setPageCache] = useState<any>({});
  const [pageSize, setPageSize] = useState(10); // Page size (rows per page)
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState<any[]>([]); // Store all data
  useEffect(() => {
    //dispatch(getAllAIChatsByDateRange(dateRange,pageSize,FChatHistoryFDBlastDoc));
    const startDateOfToday = moment(moment(new Date()).startOf("day"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const endDateOfTodat = moment(moment(new Date()).endOf("day"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const date = moment()
      .utc()
      .set({ hours: 0, minutes: 0, seconds: 0 })
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    dispatch(
      getAllAIChatsByDateRange(
        {
          startDate: startDateOfToday,
          endDate: endDateOfTodat,
        },
        pageSize,
        null
      )
    );
  }, [dispatch]);

  useEffect(() => {
    setPageCache((prevCache: any) => ({
      ...prevCache,
      [currentPage]: allChatHistory,
    }));
    setData(allChatHistory);
    setAllChats(allChatHistory);
  }, [allChatHistory]);

  const handleAdd = () => {};

  const handleEdit = () => {};

  const handleClickOpen = () => {};

  const setSelectedDate = (dateRange: any) => {
    setPageCache({});
    if (
      dateRange &&
      dateRange.hasOwnProperty("startDate") &&
      dateRange.hasOwnProperty("endDate")
    ) {
      dispatch(getAllAIChatsByDateRange(dateRange, 10, null));
    }
  };

  const convertAndDownload = (jsonData: any) => {
    const csvData = jsonToCSV(jsonData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "AIGurujiChats.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const jsonToCSV = (jsonData: any) => {
    const headers = Object.keys(jsonData[0]);
    const csv = jsonData.map((row: any) =>
      headers.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
    );
    return [headers.join(","), ...csv].join("\n");
  };

  const reformatData = (data: any) => {
    return data.map((item: any) => ({
      id: item?.id || " ",
      userId: item?.userId || " ",
      userPhone: item?.userPhone || " ",
      isDeleted: item?.isDeleted || " ",
      sessionId: item?.sessionId || " ",
      startedDate: item?.startedDate || " ",
      createdAt: item?.createdAt || " ",
      updatedAt: item?.updatedAt || " ",
      userCurrentIPAddress: item?.userCurrentIPAddress || " ",
      userCurrentLocation: item?.userCurrentLocation || " ",
      chatHistory: item?.chatHistory || " ",
    }));
  };

  const handleExport = () => {
    console.log(allChats);
    convertAndDownload(reformatData(allChats));
  };

  const handleOpenChatHistory = () => setIsChatHistoryOpen(true);
  const handleCloseChatHistory = () => setIsChatHistoryOpen(false);
  const viewChatHistory = (row: any) => {
    if (row?.chatHistory) {
      setSelectedChatHistory(row);
    }
    handleOpenChatHistory();
  };

  const handleSingleDownloadCSV = () => {
    // const chathistory =  selectedChatHistory?.chatHistory;
    convertAndDownload(reformatData([selectedChatHistory]));
  };

  const fetchChatHistoryData = (page: any, dateRange: any) => {
    setCurrentPage(page?.page || 0);
    if (pageCache[page?.page]) {
      setData(pageCache[page?.page]);
      return;
    }

    const lastDoc = pageSize === page?.pageSize ? FChatHistoryFDBlastDoc : null;
    setPageSize(page?.pageSize);
    if (
      dateRange &&
      dateRange.hasOwnProperty("startDate") &&
      dateRange.hasOwnProperty("endDate")
    ) {
      dispatch(getAllAIChatsByDateRange(dateRange, page?.pageSize, lastDoc));
    } else {
      //dispatch(getAllAIChatsByDateRange(dateRange,pageSize,FChatHistoryFDBlastDoc));
      const startDateOfToday = moment(moment(new Date()).startOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");

      // Local Date at 11:59 PM
      const endDateOfTodat = moment(moment(new Date()).endOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      const date = moment()
        .utc()
        .set({ hours: 0, minutes: 0, seconds: 0 })
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      dispatch(
        getAllAIChatsByDateRange(
          {
            startDate: startDateOfToday,
            endDate: endDateOfTodat,
          },
          page?.pageSize,
          lastDoc
        )
      );
    }
  };

  return (
    <Box>
      <AIGurujiChatList
        chatList={data}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handleDelete={handleClickOpen}
        setSelectedDate={setSelectedDate}
        handleExport={handleExport}
        viewChatHistory={viewChatHistory}
        chatHistoryTotalCount={chatHistoryTotalCount}
        fetchChatHistoryData={fetchChatHistoryData}
        isLoading={isLoading}
        pageSize={pageSize}
        page={currentPage}
        setPage={setCurrentPage}
        setPageSize={setPageSize}
      />

      <ChatHistory
        open={isChatHistoryOpen}
        onClose={handleCloseChatHistory}
        selectedChatHistory={selectedChatHistory}
        exportCSV={handleSingleDownloadCSV}
      />
    </Box>
  );
}

export default AIGurujiChatScreen;

import "./PujaView.scss";
import { Box, Grid, Typography, Stack } from "@mui/material";

import vertorArtLeft from "../../assets/vector-art-left.png";
import vertorArtRight from "../../assets/vector-art-right.png";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import { getOneImageForTemple } from "../../utils/HelperFunctions";

function PromotionalPujaView(props: any) {
  const [urlToShare, setUrlToShare] = useState<string>("");

  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // const promotionalPujaId = params.get('pujaNameKey')
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?specialPuja=${props?.promotionalPujaDetails?.pujaNameKey}`
    );
  }, [props.promotionalPujaDetails]);

  return (
    <Box className="pageContainer promotionalPujaPage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="promoPujaCenterBlock">
              <Box>
                <img
                  className="promoPujaBanner"
                  src={props.promotionalPujaDetails?.pujaListBg?.uri}
                  alt={"Image"}
                />
              </Box>
              <Box className="shareIcon">
                <ShareLink urlToShare={urlToShare}></ShareLink>
              </Box>

              {props.slectedPromotionalPujaOrganisations &&
              props.slectedPromotionalPujaOrganisations.length > 0
                ? props.slectedPromotionalPujaOrganisations.map(
                    (item: any, id: any) => {
                      return (
                        <Box>
                          <Box className="pujaBookingTitle">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box>
                                <img src={vertorArtLeft} alt="" />
                              </Box>
                              <Typography>
                                {item?.personalInfo?.name}
                              </Typography>
                              <Box>
                                <img src={vertorArtRight} alt="" />
                              </Box>
                            </Stack>
                          </Box>

                          <Box className="templeCenterBlock">
                            {item.templePuja && item.templePuja.length > 0
                              ? item.templePuja.map(
                                  (itemPuja: any, idPuja: any) => {
                                    // return null;

                                    // let templeProfilePic = getOneImageForTemple(
                                    //   item.personalInfo?.media?.pictures
                                    // );
                                    let templeProfilePic = "";
                                    return (
                                      <PujaListCard
                                        pujaData={itemPuja}
                                        pujaName={itemPuja?.pujaName}
                                        templeProfilePic={
                                          itemPuja.pujaBg?.uri ||
                                          templeProfilePic
                                        }
                                        description={itemPuja.description}
                                        benefits={itemPuja.benefits}
                                        acctualPrice={itemPuja.acctualPrice}
                                        onClickAddPuja={() => {
                                          props.onClickParticipate(
                                            item.nameKey,
                                            itemPuja.pujaNameKey
                                          );
                                        }}
                                        temple={item.personalInfo}
                                        devoteeCount={itemPuja.maxMemberAllowed}
                                        benefitList={itemPuja?.benefitList}
                                        isPujaDisable={itemPuja?.isPujaDisable}
                                      />
                                    );
                                  }
                                )
                              : null}
                          </Box>
                        </Box>
                      );
                    }
                  )
                : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PromotionalPujaView;

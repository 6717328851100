import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridPaginationModel,
} from "@mui/x-data-grid";
import {
  Box,
  ButtonGroup,
  IconButton,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getDateNowInUtcWithFormate,
  getFormattedDate,
  getFormattedDateTime,
} from "../../../utils/HelperFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Heading, HStack, VStack } from "@chakra-ui/react";
import MiniCalendar from "../../../components/calendar/MiniCalendar";
import moment from "moment";
import { FiCalendar } from "react-icons/fi";
import "./AIGurujiChatList.scss";
import { CalendarToday } from "@mui/icons-material";
//  import '../analytics.css'

const UTC_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
interface AIGurujiChatListProps {
  chatList: any[];
  handleEdit: (row: any) => void;
  handleAdd: () => void;
  handleDelete: (row: any) => void;
  setSelectedDate: any;
  handleExport: () => void;
  viewChatHistory: (row: any) => void;
  chatHistoryTotalCount: number;
  fetchChatHistoryData: any;
  isLoading: boolean;
  pageSize: any;
  page: any;
  setPage: any;
  setPageSize: any;
}

const AIGurujiChatList: React.FC<AIGurujiChatListProps> = ({
  chatList,
  handleEdit,
  handleAdd,
  handleDelete,
  setSelectedDate,
  handleExport,
  viewChatHistory,
  chatHistoryTotalCount,
  fetchChatHistoryData,
  isLoading,
  page,
  pageSize,
  setPage,
  setPageSize
}) => {
  const calenderRef = useRef<any>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dateFromCalendar, setDateFromCalendar] = useState<any>(null);
  const [selectedButton, setSelectedButton] = useState({
    isToday: true,
    is7days: false,
    is30Days: false,
    isCustom: false,
  });
  const [selectedDateRange, setSelectedDateRange] = useState({});

  // const textColor = useColorModeValue("secondaryGray.900", "white");
  // console.log("$$$ main analyticsData", analyticsData);

  const columns: GridColDef[] = [
    { field: "userPhone", headerName: "Phone Number", width: 200 },
    { field: "userName", headerName: "Name", flex: 1 },
    {
      field: "startedDate",
      headerName: "Started Date",
      flex: 1,
      renderCell: (params) => {
        const { createdAt, startedDate = null } = params?.row;
        const formattedDate = getFormattedDateTime(
          startedDate ? startedDate : createdAt
        );
        return <span>{formattedDate}</span>;
      },
    },
    { field: "isDeleted", headerName: "Is Deleted", flex: 1 },
    { field: "userCurrentLocation", headerName: "Address", flex: 1 },
    { field: "userCurrentIPAddress", headerName: "IP", flex: 1 },
    { field: "sessionId", headerName: "Session Id", flex: 1 },
    // { field: 'isActive', headerName: 'Is Active', flex: 1, type: 'boolean' },
    // {
    //   field: 'validTill', headerName: 'Valid Till', flex: 1,
    //   renderCell: (params) => {
    //     const formattedDate = getFormattedDate(params.value);
    //     return <span>{formattedDate}</span>;
    //   }

    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => viewChatHistory(params.row as any)}>
            <RemoveRedEyeIcon sx={{ color: "#dea400" }} />
          </IconButton>
          {/* <IconButton onClick={() => handleDelete(params?.row as any)} color="error">
            <DeleteIcon />
          </IconButton> */}
        </>
      ),
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dateFromCalendar?.length === 2) {
      const startDateOfToday = moment(
        moment(dateFromCalendar[0]).startOf("day")
      )
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");

      // Local Date at 11:59 PM
      const endDateOfToday = moment(moment(dateFromCalendar[1]).endOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      setSelectedDateRange({
        ...selectedDateRange,
        startDate: startDateOfToday,
        endDate: endDateOfToday,
      });
    }
  }, [dateFromCalendar]);

  useEffect(() => {
    if (selectedDateRange) {
      setPaginationModel({
        page: 0,
        pageSize: 10,
      });
      setSelectedDate(selectedDateRange);
    }
  }, [selectedDateRange]);

  const getTodaysData = () => {
    setDateFromCalendar(null);
    const startDateOfToday = moment(moment().startOf("day"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    // Local Date at 11:59 PM
    const endDateOfTodat = moment(moment().endOf("day"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    // console.log(
    //   "Date 1 (12:00 AM UTC):",
    //   startDateOfToday,
    //   moment().startOf("day")
    // );
    setSelectedButton({
      ...selectedButton,
      isToday: true,
      is7days: false,
      is30Days: false,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: startDateOfToday,
      endDate: endDateOfTodat,
    });
  };

  const getSevenDaysData = () => {
    setDateFromCalendar(null);
    setSelectedButton({
      ...selectedButton,
      is7days: true,
      isToday: false,
      is30Days: false,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: moment(moment().startOf("day"))
        .utc()
        .subtract(6, "days")
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: moment(moment().endOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    });
  };

  const getThirtyDaysData = () => {
    setDateFromCalendar(null);
    setSelectedButton({
      ...selectedButton,
      isToday: false,
      is7days: false,
      is30Days: true,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: moment(moment().startOf("day"))
        .utc()
        .subtract(30, "days")
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: moment(moment().endOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    });
  };

  const handleCloseCalendar = () => {
    setSelectedButton({
      ...selectedButton,
      is7days: false,
      isToday: false,
      is30Days: false,
      isCustom: false,
    });
  };

  //---------Pagination----
  useEffect(() => {
     if(paginationModel?.page > 0){
      fetchChatHistoryData(paginationModel, selectedDateRange);
     }
  }, [paginationModel]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };

 


  

  return (
    <Box className="pageContainer AIGurujiChatList">
      <Box className="AIGurujiChatListView">
        <Box>
          <HStack className="gurujiChatListHeadingRow">
            <Box className="gurujiChatListHeadingRight">
              <Box className="gurujiChatListBtnGroup">
                <ButtonGroup
                  variant="outlined"
                  aria-label="analytic filter buttons"
                  size="large"
                >
                  <Button
                    className="btngroupanalytics"
                    onClick={() => getSevenDaysData()}
                    color={
                      selectedButton.is7days === true ? "primary" : "inherit"
                    } // Apply 'primary' color if active, 'inherit' otherwise
                    // sx={{ padding: "14px", boxShadow: "none" }}
                  >
                    Last 7 Days
                  </Button>
                  <Button
                    className="btngroupanalytics"
                    onClick={() => getTodaysData()}
                    color={
                      selectedButton.isToday === true ? "primary" : "inherit"
                    } // Apply 'primary' color if active, 'inherit' otherwise
                    // sx={{ padding: "14px", boxShadow: "none" }}
                  >
                    Today's
                  </Button>
                  <Button
                    className="btngroupanalytics"
                    onClick={() => getThirtyDaysData()}
                    color={
                      selectedButton.is30Days === true ? "primary" : "inherit"
                    } // Apply 'primary' color if active, 'inherit' otherwise
                    // sx={{ padding: "14px", boxShadow: "none" }}
                  >
                    Last 30 Days
                  </Button>
                </ButtonGroup>
              </Box>
              <Box className="dateRangeBlock">
                <Box ref={calenderRef}>
                  <VStack className="dateRangeBtn">
                    <Button
                      onClick={() => {
                        setSelectedButton({
                          ...selectedButton,
                          isToday: false,
                          is7days: false,
                          is30Days: false,
                          isCustom: true,
                        });
                        setDateFromCalendar(null);
                      }}
                      size="small"
                      startIcon={<CalendarToday />}
                      variant={
                        selectedButton.isCustom ? "contained" : "outlined"
                      }
                      sx={{
                        border: "1px solid",
                        color: selectedButton.isCustom ? "primary" : "default",
                      }}
                    >
                      {dateFromCalendar?.length !== 2
                        ? "Date Range Selection"
                        : `${moment(dateFromCalendar[0]).format(
                            "YYYY-MM-DD"
                          )} to ${moment(dateFromCalendar[1]).format(
                            "YYYY-MM-DD"
                          )}`}
                    </Button>
                    {selectedButton.isCustom &&
                    dateFromCalendar?.length !== 2 ? (
                      <Box className="rangeCalendar">
                        <MiniCalendar
                          className="dateCalendar"
                          selectRange={true}
                          pos="absolute"
                          m="20px 0 0 0"
                          border="2px solid black"
                          zIndex="1"
                          transform="scale(0.6)"
                          transformOrigin="top"
                          dateFromCalendar={dateFromCalendar}
                          setDateFromCalendar={setDateFromCalendar}
                          handleCloseCalendar={handleCloseCalendar}
                        />
                      </Box>
                    ) : null}
                  </VStack>
                </Box>
              </Box>
            </Box>
            <Box className="btnAddCoupon">
              <Button onClick={handleExport} variant="contained">
                <FileDownloadIcon /> Export
              </Button>
            </Box>
          </HStack>
        </Box>
        <Paper elevation={3}>
          <Box className="couponDataList">
            {/* <DataGrid
              className="couponDataGrid"
              rows={chatList}
              columns={columns}
              paginationMode="server"
              paginationModel={paginationModel}
              rowCount={chatHistoryTotalCount}
              onPaginationModelChange={handlePaginationModelChange}
              loading={isLoading}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              autoHeight
            /> */}
          
            <DataGrid
              className="couponDataGrid"
              rows={chatList}
              columns={columns}
              paginationMode="server"
              paginationModel={paginationModel}
              rowCount={chatHistoryTotalCount}
              onPaginationModelChange={handlePaginationModelChange}
              pagination
              loading={isLoading}
              pageSizeOptions={[10]}
              autoHeight
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AIGurujiChatList;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  UserService,
  TempleService,
  PanchangService,
  CommonService,
} from "td_server";
import {
  setLoading,
  setSelectedTempleDetailsForPuja,
  setPromotionalList,
  setSelectedPromotionalPujaOrganisations,
  setPujaCategoryList,
  setPujaMergedSubCategoryList,
} from "./../slices/pujaSlice";
export const getTempleDetailsById =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsById(
        templeId
      );
      // console.log("response = ", response);
      dispatch(setSelectedTempleDetailsForPuja(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPromotionalPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalPujaDashBoardListActive();
      // console.log("response = ", response);
      dispatch(setPromotionalList(response?.promotionalList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getSpecialPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalPujaDashBoardListActiveInactive();
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPromotionalPujaListById =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaListById(id);
      console.log("response promotional by id = ", response);
      dispatch(setSelectedPromotionalPujaOrganisations(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      // return Promise.reject("");
    }
  };

export const getPromotionalPujaListByNameKey =
  (nameKey: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaListByNameKey(
        nameKey
      );
      console.log("response promotional by name = ", response);
      dispatch(setSelectedPromotionalPujaOrganisations(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log("getPanchangData  ERROR : ", e);
      dispatch(setLoading(false));
      // return Promise.reject("");
    }
  };

export const getTemplePujaCategoryList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getTemplePujaCategoryList();
      // console.log("response Category res = ", response);
      dispatch(setPujaCategoryList(response?.categoryList));
      dispatch(setPujaMergedSubCategoryList(response?.mergedSubCategory));

      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addPromotionalPujaAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.addPromotionalPujaFromWeb(data);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const deletePromotionalPujaAction =
  (templePuja: any, specialPujaId: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.deletePromotionalPuja(
        templePuja,
        specialPujaId
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

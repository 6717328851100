import { useEffect, useLayoutEffect, useState } from "react";
import ChadhawaView from "../../Views/Admin/Chadhawa/ChadhawaView";
import { useDispatch, useSelector } from "react-redux";
import TempleView from "../../Views/Admin/Temple/TempleView";
import { updateOrganization } from "../../store/actions/templeAction";
const  TempleAdminScreen:React.FC<any> = ()=> {
  const dispatch: any = useDispatch();
  const {  allTempleList } = useSelector((state: any) => state.temple);
  const [temples, setTemples] = useState<any>([])

  useLayoutEffect(() => {
    setTemples(allTempleList)
  }, [allTempleList])

  const handleChadhavaToggle = (temple: any) => {
    if(temple && temple?.id){
      const value = !temple.isChadhavaEnabled
      updateOrganizationById(temple.id,"isChadhavaEnabled",value)
    }
 };

  const handleDisableToggle = (temple: any) => {
    if(temple && temple?.id){
      const value = !temple.isClosed
      updateOrganizationById(temple.id,"isClosed",value)
    }
  };

  const handleIsClosedBooking = (temple: any) => {
    if(temple && temple?.id){
      const value = !temple.isClosedBooking
      updateOrganizationById(temple.id,"isClosedBooking",value)
     
    }
  };

  const handlePositonSubmitFortemple = (templeId:any,templePositionValue:any) => {
    if(templeId && templePositionValue){
      updateOrganizationById(templeId,"position",templePositionValue)
    }
  };

  const handleTogglePrasadForTemple = (temple: any) => {
    if(temple && temple?.id){
      const value = !temple.isPrasadEnabled
      updateOrganizationById(temple.id,"isPrasadEnabled",value)
    }
  };

const updateOrganizationById = (id:string,key:string,value:boolean)=> {
  dispatch(updateOrganization(id,key,value))
}
  
  return (
    <TempleView
      temples={temples}
      handleChadhavaToggle={handleChadhavaToggle}
      handleDisableToggle={handleDisableToggle}
      handleIsClosedBooking={handleIsClosedBooking}
      handlePositonSubmitFortemple={handlePositonSubmitFortemple}
      handleTogglePrasadForTemple={handleTogglePrasadForTemple}
    />
  );
}

export default TempleAdminScreen;

import {createSlice} from '@reduxjs/toolkit';

const PrasadSlice = createSlice({
  name: 'prasad',
  initialState: {
    isLoading: false,
    prasadTempleList:[],
    error:"",
    prasadList:[],
    templePrasadList:[],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPrasadTempleList: (state, action) => {
      state.prasadTempleList = action.payload;
    },
    setPrasadForTemple: (state, action) => {
      state.prasadList = action.payload;
    },
    setTemplePrasadList: (state, action) => {
      state.templePrasadList = action.payload;
    },
    
  },
});

export const {
  setLoading,
  setError,
  setPrasadTempleList,
  setPrasadForTemple,
  setTemplePrasadList,
} = PrasadSlice.actions;

export default PrasadSlice.reducer;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import valmiki from "../../assets/valmiki.png";
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import './AIGurujiLoginDialog.scss'

const Transition = React.forwardRef(function Transition(

  props: TransitionProps & {

    children: React.ReactElement<any, any>;

  },

  ref: React.Ref<unknown>,

) {

  return <Slide direction="up" ref={ref} {...props} />;

});

interface AIGurujiLoginDialogProps {
  openModal: boolean,
  closeModal: (type:string) => void
}

export default function AIGurujiLoginDialog({ openModal, closeModal }: AIGurujiLoginDialogProps) {
  const navigate = useNavigate();
  return (
    <React.Fragment>

      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        className="aIGurujiLoginDialog"
        onClose={() => {
          closeModal("close")
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="aiGuruLoginDialogTitle">
          {/* <Typography className="icon">🚀</Typography> */}
          <Box 
            component="img"
            src={valmiki}
            alt="Image"
          />
          <Typography className="mainTitle">Unlock Free Chat with Valmiki, Your AI Guruji!</Typography>
        </DialogTitle>

        <DialogContent className="aiGuruLoginDialogContent">
          <DialogContentText className="subText" id="alert-dialog-slide-description">
            To ensure the best predictions and personalized experience, please provide a few details by logging in or registering. Start your journey with Valmiki, your own AI Guruji, and enjoy free chat tailored just for you!
          </DialogContentText>
        </DialogContent>

        <DialogActions className="bottomBtnContent">
          <Button variant="outlined" onClick={() => {
            closeModal("close")
          }}>Cancel</Button>
          <Button className="loginBtn" variant="contained" onClick={() => {
            navigate("/login")
            closeModal("login")
          }}>Login</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

  );

}

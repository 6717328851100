import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveCountryList } from "../../store/actions/authAction";
import { Box, Dialog, DialogTitle, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { SearchIcon } from "@chakra-ui/icons";
import "./CountrySelection.scss"
import { Button, Stack } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

  const  CountrySelection = (props:any)=> {
    const { onClose, selectedCountryCode, open, selectedDialCode} = props;
    const [searchQuery, setSearchQuery] = useState("");
    const [countryCodeList, setCountryCodeList] = useState([]);
    const dispatch: any = useDispatch();
    const { countryCode } = useSelector((state: any) => state.auth);
    useEffect(() => {
      if (countryCode) {
        setCountryCodeList(countryCode);
      }
    }, [countryCode]);
  
    useEffect(() => {
      dispatch(getActiveCountryList());
    }, []);
  
    const pickImage = (country: any) => {
      onClose({
        flag: `https://flagcdn.com/w320/${country.code.toLowerCase()}.png`,
        dialCode: country.dial_code,
        code: country.code,
      });
    };
    const handleClose = (e: any) => {
      pickImage({
        code: selectedCountryCode || "IN",
        dial_code: selectedDialCode || "91",
      });
    };
  
    const handleListItemClick = (country: any) => {
      pickImage(country);
    };
  
    const handleSearchChange = (event: {
      target: { value: React.SetStateAction<string> };
    }) => {
      setSearchQuery(event.target.value);
    };
  
    const filteredCountries = countryCodeList?.filter((country: any) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    return (
      <Dialog
        className="countryDilog"
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            width: "550px",
            height: "650px",
            maxWidth: "100%",
          },
        }}
      >
        
        <Box className="topCountryDialog">
          <Typography className="pickCodeText">
            Pick your <br /> Country Code
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            color="primary"
            className="topCloseBtn"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Box className="searchContainer">
          <Box className="searchWrapper">
            <InputBase
              placeholder="Search here for your country."
              className="searchInput"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <SearchIcon className="searchIcon" />
          </Box>
        </Box>
  
        <List className="countrylist">
          {filteredCountries.map((country: any) => (
            <ListItem disableGutters key={country.code}>
              <ListItemButton
                onClick={() => handleListItemClick(country)}
                className={`listItemButton ${
                  selectedCountryCode === country.code
                    ? "listItemButtonSelected"
                    : ""
                }`}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={`https://flagcdn.com/w320/${country.code.toLowerCase()}.png`}
                    alt={country.name}
                    className="flagImage"
                  />
                  <ListItemText
                    primary={country.name}
                    primaryTypographyProps={{
                      style: { fontSize: "14px", fontWeight: "bold" },
                    }}
                  />
                </Box>
                <Box className="dialCode">{country.dial_code}</Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

export default CountrySelection;
import { Box, Grid, Typography, Button } from "@mui/material";
import {
  calculateOverSeasPrice,
  getDateFormatedForBookingDate,
  getDateFormatedOrderTime,
} from "../../utils/HelperFunctions";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import "./MyOrdersView.scss";
import { getStatusImage } from "../../utils/Constants";

function MyOrdersView(props: any) {
  console.log("Props", props);
  const handleOpenViewDetails = (orderId: string) => {
    props?.OpenViewDetailsModal(orderId);
  };

  // console.log("Order history", props.orderHistory);
  return (
    <>
      <Box className="pageContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className="pageTopTitle">Order history</Typography>
            {props.orderHistory.map((item: any, id: any) => {
              let orderStatus = item.orderStatus?.length
                ? item.orderStatus[item.orderStatus.length - 1].orderStatus
                : "";
                // orderStatus = "DELIVERED"
              return (
                <Box className="pujaAddedArea">
                  <Box className="pujaAddedDetails">
                    <Box className="pujaAddedLeft">
                      <Typography>Order id: #{item.orderId}</Typography>
                    </Box>
                    <Box className="pujaAddedRight">
                      <Typography>
                        <Typography style={{fontWeight: "bold"}}>
                        {orderStatus !== 'NEW' ? orderStatus:"" }{" "}
                        </Typography>
                        {/* <img src={getStatusImage(orderStatus)} alt=""></img> */}
                        <img src={getStatusImage(orderStatus)} style={{marginLeft:"20px"}} alt=""></img>
                        
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="pujaAddedMember">
                    <Box className="pujaAddedinfo">
                      <Typography className="title">{item.pujaName}</Typography>
                      <Typography>
                        Booked on {" "}
                        {getDateFormatedOrderTime(item.orderBookingDate)}
                      </Typography>
                      {item.pujaDate !== 'undefined' && (<Typography>
                        Booked for{" "}
                        {getDateFormatedForBookingDate(item.pujaDate)}
                      </Typography>)}
                      

                      <Typography>
                        {/* Total: ₹ {item?.orderData?.payment?.orderValue} */}
                        Total: { item?.orderData?.currencySymbols ? item?.orderData?.currencySymbols : "₹"} 
                        {/* { item?.orderData?.isOverseas === true ? calculateOverSeasPrice(item?.orderData?.payment?.orderValue,item?.orderData?.rate) :
                         item?.orderData?.payment?.orderValue} */}
                         { item?.orderData?.isOverseas ?  item?.orderData?.payment?.orderValueOv  ? item?.orderData?.payment?.orderValueOv : calculateOverSeasPrice(item?.orderData?.payment?.orderValue,item?.orderData?.rate) : item?.orderData?.payment?.orderValue}
                      </Typography>
                    </Box>
                    <Box className="viewDetailsBtn">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenViewDetails(item.orderId);
                        }}
                      >
                        {" "}
                        VIEW DETAILS{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MyOrdersView;

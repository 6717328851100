import { useEffect, useState } from "react";
import CouponView from "../../Views/Admin/Coupon/CouponView";
import ParentView from "../../Views/PrarentView/ParentView";
import { TempleService } from "td_server";
import {
  setChadhavaForTemple,
  setChadhavaTempleList,
} from "../../store/slices/ChadhawaSlice";
import {
  getChadhavaTempleList,
  getChadhavaForTemple,
  updateChadhavaDetailsForTemple,
  addChadhavaDetailsForTemple,
  updateChadhavaBenefitsForTemple,
  updateChadhavaDateForTemple,
  resetChadhavaList,
  deleteChadhavaItem,
} from "../../store/actions/ChadhawaAction";
import { useDispatch, useSelector } from "react-redux";
import ChadhawaView from "../../Views/Admin/Chadhawa/ChadhawaView";
import dayjs from "dayjs";
import { calculateChadhavaPrice } from "../../utils/HelperFunctions";
import { updateOrganization } from "../../store/actions/templeAction";
function ChadhawaItemsScreen() {
  //getChadhavaForTemple

  const dispatch: any = useDispatch();
  const [ChadhavaTempleList, setChadhavaTempleList] = useState([]);
  const [templeBenefitsList, setTempleBenefitsList] = useState([]);
  const [chadhavaListLocal, setChadhavaListLocal] = useState<any>(null);
  const [selectedTempleId, setSelectedTempleId] = useState<string>("");
  const [selectedTemple, setSelectedTemple] = useState<any>(null);
  const [selectedBenefitsOptions, setSelectedBenefitsOptions] = useState<any>();
  const { isLoading, error, chadhavaList } = useSelector(
    (state: any) => state.chadhawa
  );

  useEffect(() => {
    setChadhavaListLocal(chadhavaList);
  }, [chadhavaList]);

  useEffect(() => {
    //Call first time
    dispatch(resetChadhavaList());
  }, []);

  const { benefitsList, allTempleList } = useSelector(
    (state: any) => state.temple
  );
  useEffect(() => {
    // dispatch(getChadhavaTempleList());
  }, [allTempleList]);

  const handleSetSelectedTemple = (temple: any) => {
    dispatch(resetChadhavaList());
    if (temple?.nameKey && temple?.id) {
      setSelectedTempleId(temple?.id);
      setSelectedTemple(temple);

      dispatch(getChadhavaForTemple(temple?.nameKey));
      if (temple?.chadhavaBenefits) {
        const benefitIds: any = [];
        temple?.chadhavaBenefits?.forEach((el: any) => {
          benefitIds.push(el?.benefitId);
        });

        setSelectedBenefitsOptions(benefitIds);
      }
    }
  };
  const handleSaveChadhawaItem = async (item: any) => {
    let imageUrl = "";
    console.log("imageUrl", imageUrl);
    const data = Object.assign({}, item);

    let priceInfo = calculateChadhavaPrice(Number(item?.acctualPrice));
    // console.log("Price Info", priceInfo);
    data.priceInfo = priceInfo;
    console.log("Chadhawa details===>", item);
    delete data.id;
    delete data.imageFile;
    if (item?.imageFile) {
      imageUrl = await TempleService.uploadImageForChadhava({
        uri: item?.imageFile,
        platform: "web",
      });
      data.image = imageUrl;
    }
    console.log(item, imageUrl, data);

    ///-----------
    if (item?.id && item?.id?.trim().length > 0) {
      dispatch(
        updateChadhavaDetailsForTemple(selectedTempleId, item.id, data)
      ).then(() => {
        dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
      });
    } else {
      dispatch(addChadhavaDetailsForTemple(selectedTempleId, data)).then(() => {
        dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
      });
    }
  };

  const handleBenefitsSelect = (items: any) => {
    // handleBenefitsSelect
    //console.log("item---", items);
    // if (selectedTempleId) {
    //   dispatch(updateChadhavaBenefitsForTemple(selectedTempleId, items));
    // }
    setTempleBenefitsList(items);
  };

  const handleBenefitsSubmit = () => {
    if (selectedTempleId && templeBenefitsList.length > 0) {
      dispatch(
        updateChadhavaBenefitsForTemple(selectedTempleId, templeBenefitsList)
      );
      const benefitIds: any = [];
      templeBenefitsList?.forEach((el: any) => {
        benefitIds.push(el?.benefitId);
      });

      setSelectedBenefitsOptions((prev: any[]) => {
        const newOptions = [...prev, ...benefitIds];
        const uniqueOptions = Array.from(new Set(newOptions));
        return uniqueOptions;
      });
    }
  };

  const handleTempleWeightageSubmit = (weightage:any)=>{
    if (selectedTempleId && selectedTemple && weightage) {
      dispatch(updateOrganization(selectedTempleId,"chadhavaWeightageForTemple",weightage))
    }
  }
  
  // const saveChadhavaDate = ()=>{
  //   const data = [...selectedDays,...selectedDates]
  //   if(selectedTempleId && data?.length > 0){
  //     dispatch(updateChadhavaDateForTemple(selectedTempleId,data))
  //   }
  // }

  const handleDeleteChadhavaItem = (item: any) => {
    dispatch(deleteChadhavaItem(selectedTempleId, item.id)).then(() => {
      dispatch(resetChadhavaList());
      dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
    });
  };

  return (
    <ChadhawaView
      temples={allTempleList}
      setSelectedTemple={handleSetSelectedTemple}
      chadhavaList={chadhavaListLocal}
      saveChadhawaItem={handleSaveChadhawaItem}
      benefitsList={benefitsList}
      selectedBenefitsOptions={selectedBenefitsOptions}
      handleBenefitsSelect={handleBenefitsSelect}
      handleBenefitsSubmit={handleBenefitsSubmit}
      selectedTempleId={selectedTempleId}
      deleteChadhavaItem={handleDeleteChadhavaItem}
      selectedTemple={selectedTemple}
      handleTempleWeightageSubmit={handleTempleWeightageSubmit}
      // handleSetSelectedDays={handleAddSelectedDays}
      // handleRemoveSelectedDays={handleRemoveSelectedDays}
      // selectedDays = {selectedDays}
      // handleDateChange={handleDateChange}
      // setSelectedDates={setSelectedDates}
      // selectedDates={selectedDates}
      // saveChadhavaDate={saveChadhavaDate}
    />
  );
}

export default ChadhawaItemsScreen;

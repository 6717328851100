/* eslint-disable @typescript-eslint/no-unused-vars */

import { AiGurujiChatService } from "td_server";
import {
  setLoading,
  setChatByUserId,
  startDownload,
  finishDownload,
  setAllChatHistory,
  setChatHistoryFDBlastDoc,
  setChatHistoryTotalCount,
} from "../slices/gurujiChatSlice";
import { getDynamicPdfFileName } from "../../utils/HelperFunctions";
import { setMessageToReactNative } from "../../utils/WebNativeFunctions";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const createChat =
  (userId: any,userName:string, data: any, userPhone: any,sessionId: string, userCurrentLocation: string, userCurrentIPAddress: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.createAIChatByUserId(
        userId,
        userName,
        data,
        userPhone,
       sessionId, 
       userCurrentLocation,
       userCurrentIPAddress
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updateChat =
  (userId: any, userName:string, chatHistory: any, currentPayload: any,sessionId: string, userCurrentLocation: string, userCurrentIPAddress: string,isDeleted:boolean) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.updateAIChatByChatId(
        userId,
        userName,
        chatHistory,
        currentPayload,
        sessionId, 
        userCurrentLocation,
        userCurrentIPAddress,
        isDeleted
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getChatByUserId =
  (userId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.getAIChatByUserId(userId);
      if (response) {
        dispatch(setChatByUserId(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setChatByUserId(null));
      dispatch(setLoading(false));
      return Promise.reject(null);
    }
  };

  

  export const getAllAIChatsByDateRange = (dateRange:any,page:number,lastDoc=null) => async (dispatch: any, getState: any) => {
    setAllChatHistory([])
    // dispatch(setChatHistoryTotalCount(0));
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.getAllAIChatsByDateRange(dateRange,page,lastDoc);
      console.log(response);
      if (response) {
        const {chatData,lastDoc,totalCount} = response;
        dispatch(setAllChatHistory(chatData));
        dispatch(setChatHistoryFDBlastDoc(lastDoc));
        dispatch(setChatHistoryTotalCount(totalCount));
      }else{
        dispatch(setAllChatHistory([]));
        dispatch(setChatHistoryFDBlastDoc(null));
        dispatch(setChatHistoryTotalCount(0));
      }
      dispatch(setLoading(false));
      return Promise.resolve([]);
    } catch (e) {
      dispatch(setLoading(false));
        dispatch(setAllChatHistory([]));
        dispatch(setChatHistoryFDBlastDoc(null));
        dispatch(setChatHistoryTotalCount(0));
      return Promise.reject([]);
    }
  };

  

export const deleteChat =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.deleteAIChatByChatId(id);
      dispatch(setLoading(false));
      dispatch(setChatByUserId(null));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const disableAIChatByUserId =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await AiGurujiChatService.disableAIChatByUserId(id);
      dispatch(setLoading(false));
      dispatch(setChatByUserId(null));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const downloadGurujiChatPDF =
  (payload: any, isMobileView: boolean) =>
  async (dispatch: any, getState: any) => {
    dispatch(startDownload());
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic dGVtcGxlZGVraG9hcGl1c2VyOnhjdEAzNGFzMTF6Y3Y="
      );

      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify(payload);
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      const hostname =
        window.location.hostname === "localhost"
          ? "https://apnamandir-test.azurewebsites.net"
          : "https://" + window.location.hostname;
      fetch(`${hostname}/td/v2/dataToPdf/getAiGuruJiChatPdf`, requestOptions)
        .then((response) => response.json())
        .then((data) => {

          if (isMobileView && !data.isError) {
            setMessageToReactNative(
              JSON.stringify({ isChatPayoad: true, pdfUrl: data?.pdfUrl })
            );
            dispatch(finishDownload());
            return;
          }
          if(!data?.isError){
            window.open(data?.pdfUrl,"_blank")
          }
          dispatch(finishDownload());
        })
        .catch((error) => console.error("Error:", error));
    } catch (e) {
      dispatch(finishDownload());
      return Promise.reject("");
    }
  };

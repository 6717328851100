import React, { useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import "./AIGurujiVastuSummary.scss";
import {labels} from '../../../../utils/ConstantsMessages'
function AIGurujiVastuSummary(props: any) {
  return (
    <>
      <Box className="vastuSummary">
        <Typography className="summaryTitle">{props?.selectedLang === "HI" ? labels["HI"]?.VastuReportSummaryTitle : labels["EN"]?.VastuReportSummaryTitle}</Typography>
        <Box className="summaryReport">
          <Typography>
           {props.text}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default AIGurujiVastuSummary;

import React from "react";
import "./AIGurujiCalculationChart.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CalculationChart } from "../../../../utils/ConstantsMessages";
import { formatNumberToString } from "../../../../utils/HelperFunctions";

interface AIGurujiCalculationChartInterface {
  currentPayload: Record<string, any>;
  handleScrollTobirthChartForm?: (id: string | number) => void;
  id: string | number;
  nameTitle?: string;
  isTitleEnabled?: boolean;
}

const AIGurujiCalculationChart = ({
  currentPayload,
  handleScrollTobirthChartForm,
  id,
  nameTitle = "Name",
  isTitleEnabled = true,
}: AIGurujiCalculationChartInterface) => {
  let name = currentPayload?.extra_parameters?.name
    ? currentPayload?.extra_parameters?.name
    : currentPayload?.name;
  let hour = currentPayload?.extra_parameters?.hour
    ? currentPayload?.extra_parameters?.hour
    : currentPayload?.hour  || "0";
  let minutes = currentPayload?.extra_parameters?.minutes
    ? currentPayload.extra_parameters.minutes
    : currentPayload?.minutes || "0";
  let gender = currentPayload?.extra_parameters?.gender
    ? currentPayload?.extra_parameters?.gender
    : currentPayload?.gender;
  let city = currentPayload?.extra_parameters?.city
    ? currentPayload?.extra_parameters?.city
    : currentPayload?.city;
  let nation = currentPayload?.extra_parameters?.nation
    ? currentPayload?.extra_parameters?.nation
    : currentPayload?.nation;

  let year = currentPayload?.extra_parameters?.year
    ? currentPayload?.extra_parameters?.year
    : currentPayload?.year;

  let month = currentPayload?.extra_parameters?.month
    ? currentPayload?.extra_parameters?.month
    : currentPayload?.month;

  let day = currentPayload?.extra_parameters?.day
    ? currentPayload?.extra_parameters?.day
    : currentPayload?.day;

  const date = new Date(year, month - 1, day);

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    weekday: "long",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);
  const hasError = !(
    (currentPayload && Object.keys(currentPayload).length > 0) ||
    (currentPayload?.extra_parameters &&
      Object.keys(currentPayload.extra_parameters).length > 0)
  );

  return (
    <Box className="birthDetailsContainer">
      {isTitleEnabled && (
        <Typography className="description">
          {CalculationChart.description}:
        </Typography>
      )}
      <Box className="editDetailsBtn">
        <Button
          variant="outlined"
          className="editBtn"
          endIcon={<EditIcon />}
          onClick={(e) => {
            e.preventDefault();
            if (handleScrollTobirthChartForm) {
              handleScrollTobirthChartForm(id);
            }
          }}
        >
          {CalculationChart.editText}
        </Button>
      </Box>

      {!hasError && (
        <TableContainer  className="detailsOfTable">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{nameTitle}</TableCell>
                <TableCell>{name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{CalculationChart.dateOfBirth}</TableCell>
                <TableCell>{formattedDate}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{CalculationChart.gender}</TableCell>
                <TableCell>{gender ? gender : "N/A"} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{CalculationChart.timeOfBirth}</TableCell>
                <TableCell>
                  {formatNumberToString(hour)}
                  {`:`}
                  {formatNumberToString(minutes)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{CalculationChart.cityOfBirth}</TableCell>
                <TableCell>{city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{CalculationChart.countryOfBirth}</TableCell>
                <TableCell>{nation}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AIGurujiCalculationChart;

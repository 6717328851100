import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

interface BenefitOption {
  id: string;
  benefitId: string;
  category: string;
  url: string;
  name: string;
  description: string;
}

interface MultipleSelectBeneFitsProps {
  options: BenefitOption[];
  onChange: (selected: BenefitOption[]) => void;
  label: string;
  placeholder: string;
  alreadySelectedOptions?: string[];
}

const MultipleSelectBeneFits: React.FC<MultipleSelectBeneFitsProps> = ({
  options,
  onChange,
  label,
  placeholder,
  alreadySelectedOptions = [],
}) => {
  const [selectedOptions, setSelectedOptions] = useState<BenefitOption[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: BenefitOption[]) => {
    setSelectedOptions(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if (alreadySelectedOptions.length > 0) {
      const items = options.filter((item) => alreadySelectedOptions.includes(item.benefitId));
      setSelectedOptions(items);
    }
  }, [alreadySelectedOptions, options]);

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedOptions}
      onChange={handleChange}
      sx={{ pt: "6px" }}
      renderTags={(value: BenefitOption[], getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default MultipleSelectBeneFits;

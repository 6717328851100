import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";
import quarkgenLogo from "../../../../assets/poweredby-quarkgen.png";
import "./AIGurujiPoweredByAdd.scss";
import { setMessageToReactNative } from "../../../../utils/WebNativeFunctions";

function AIGurujiPoweredByAdd({isMobileView}:any) {
  return (
    <div
    style={{cursor:"pointer", textDecoration: "none"}}
    onClick = {()=>{
      if (isMobileView) {
        // setMessageToReactNative(
        //   JSON.stringify({ isChatPayoad: true, pdfUrl: "https://www.quarkgen.ai/" })
        // );
        return;
      }else{
        window.open("https://www.quarkgen.ai/","_blank")
      }
    }
    }
    >
      <Stack className="poweredByText" direction="row" spacing={1}>
        <Typography>Powered by <span>QuarkGen AI</span></Typography>
        <Box component="img" src={quarkgenLogo} alt="QuarkGen Logo" />
      </Stack>
    </div>
  );
}

export default AIGurujiPoweredByAdd;

import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import UploadIcon from "../../../../assets/upload-icon.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./AIGurujiVastu.scss";
import DeleteConfirmDialog from "../../../../components/dialog/DeleteConfirmDialog";
import { labels } from "../../../../utils/ConstantsMessages";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { setMessageToReactNative } from "../../../../utils/WebNativeFunctions";
function AIGurujiVastu(props: any) {
  const { selectedLang, isDisabled, isMobileView } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSubmitVastuForm, setIsSubmitVastuForm] = useState<boolean>(false);
  const [langLabels, setlangLabels] = useState<any>(
    labels[selectedLang === "EN" ? "EN" : "HI"]
  );
  const [fileErrorType, setFileErrorType] = useState("");
  const [fileType, setFileType] = useState<string>("");
  const sampleVastuImage =
    "https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/addmodal%2Fsample-image-vastu%20(3).png?alt=media&token=e0f2461d-dbce-4fa1-aa1a-2e3e40e4858d";
  const openFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFileType(file?.type);
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    setUploadedFiles([]);
    setPreviewUrl(null);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (props?.chat?.formData) {
      const formData = JSON.parse(props?.chat?.formData) || [];
      if (formData?.imageUrl) {
        const extension = formData?.imageUrl
          ?.split(".")
          .pop()
          .split(/\#|\?/)[0]
          .toLowerCase();
        if (extension === "pdf") {
          setFileType("application/pdf");
        }
        setPreviewUrl(formData?.imageUrl);
      }
    }
  }, [props?.chat]);

  useEffect(() => {
    if (isDisabled) {
      setIsSubmitVastuForm(isDisabled);
    }
  }, [isDisabled]);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((fErr: any) => {
          if (fErr.code === "file-too-large") {
            setFileErrorType("file-too-large");
          }

          if (fErr.code === "file-invalid-type") {
            setFileErrorType("file-invalid-type");
          }
        });
      });
    }

    if (acceptedFiles.length > 0) {
      setUploadedFiles(acceptedFiles);
      openFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/png": [],
        "image/jpeg": [],
        "image/jpg": [],
        "application/pdf": [],
      },
      maxSize: 20 * 1024 * 1024, // 20 MB
      disabled: isSubmitVastuForm || isDisabled,
    });

  const submitForm = (e: any) => {
    e.preventDefault();
    setIsSubmitVastuForm(true);
    props.sumitVastuForm(uploadedFiles);
  };

  useEffect(() => {
    if (selectedLang && !isDisabled) {
      setlangLabels(labels[selectedLang === "EN" ? "EN" : "HI"]);
    }
  }, [selectedLang, isDisabled]);

  return (
    <Box className="vastuSection">
      <Typography className="vastuTitle">{langLabels.VastuTitle}</Typography>

      {/* === Upload Image Area === */}

      <Box className="uploaderContainer">
        <Typography className="uploadTitle">
          {langLabels.VastuUploadTitle}
        </Typography>
        <input {...getInputProps()} />
        <Box
          className="uploaderBox"
          {...getRootProps()}
          sx={{
            padding: previewUrl ? "0.5rem" : "2rem",
          }}
        >
          {previewUrl ? (
            fileType === "application/pdf" ? (
              <Box className="previewBox">
                <PictureAsPdfOutlinedIcon fontSize="large" />
              </Box>
            ) : (
              <Box className="previewBox">
                <img
                  src={previewUrl}
                  alt="Uploaded Preview"
                  className="previewImage"
                />
              </Box>
            )
          ) : (
            <Box className="askToUpload">
              <Box className="uploaderIcon">
                <img src={UploadIcon} alt="Upload Icon" />
              </Box>
              <Typography className="uploadLink" variant="h6">
                {isDragActive
                  ? "Drop files here..."
                  : langLabels?.VastuVDragNdropLabel}
              </Typography>
              <Typography variant="body2" className="supportedFormats">
                {langLabels.VastuSupportedFormatTitle}: PNG, JPG, JPEG, PDF
              </Typography>
              {fileErrorType && (
                <Typography className="errorShow">
                  <ErrorOutlineIcon />
                  {fileErrorType === "file-too-large"
                    ? langLabels?.UploadFileSizeError
                    : langLabels?.UploadFileFormateError}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box className="uploaderFooter">
          <Typography variant="caption" className="maxSize">
            {langLabels.VastuUploadImgSize}: 20 MB
          </Typography>
          <Typography variant="caption" className="referSample">
            {/* <a
              href={`/sample-image-vastu.png`}
              download="sample-image-vastu.png"
              className="sampleLink"
            >
              {langLabels.VastuImgSample}
            </a> */}
            <span>
              {langLabels.VastuImgRefer}
              <span
                style={{ cursor: "pointer" }}
                className="sampleLink"
                onClick={() => {
                  if (isMobileView) {
                    setMessageToReactNative(
                      JSON.stringify({
                        isChatPayoad: true,
                        pdfUrl: sampleVastuImage,
                      })
                    );
                    return;
                  } else {
                    window.open(sampleVastuImage, "_blank");
                  }
                }}
              >
                {langLabels.VastuImgSample}
              </span>
            </span>
            {/* <a
              href="https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/addmodal%2Fsample-image-vastu%20(3).png?alt=media&token=e0f2461d-dbce-4fa1-aa1a-2e3e40e4858d"
              download="https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/addmodal%2Fsample-image-vastu%20(3).png?alt=media&token=e0f2461d-dbce-4fa1-aa1a-2e3e40e4858d"
              className="sampleLink"
              target="_blank"
            >
              {langLabels.VastuImgSample}
            </a> */}
          </Typography>
        </Box>
      </Box>

      {/* === Image Delete Area === */}
      {uploadedFiles.length > 0 && (
        <Box className="imageDeleteSec">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <DeleteConfirmDialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
              msg="Do you really want to delete the file ?"
            />
            <Typography className="imageName">
              {uploadedFiles[0]?.name}
            </Typography>
            {!isSubmitVastuForm && (
              <IconButton
                className="imageDeleteBtn"
                onClick={handleDeleteClick}
                disabled={isSubmitVastuForm || isDisabled}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
      )}

      {/* === Generate Button Area === */}
      <Button
        variant="contained"
        className="generateBtn"
        endIcon={<KeyboardDoubleArrowRightIcon />}
        onClick={submitForm}
        disabled={uploadedFiles.length === 0 || isSubmitVastuForm}
      >
        {langLabels?.VastuShastra}
      </Button>
    </Box>
  );
}

export default AIGurujiVastu;
